import { Client } from "@stomp/stompjs";

export const Types = {
  MONITOR_REPORTS_START: "MONITOR_REPORTS_START",
  MONITOR_REPORTS_SUCCESS: "MONITOR_REPORTS_SUCCESS",
  MONITOR_REPORTS_END: "MONITOR_REPORTS_END",
  FILTER_ACK_RECEIVED: "FILTER_ACK_RECEIVED",
};

const websocketUrl = `${process.env.REACT_APP_BASE_URL}/ws`;
let stompClient = null;

export const InitializeWebSocket = () => (dispatch) => {
  dispatch({ type: Types.MONITOR_REPORTS_START });

  stompClient = new Client({
    brokerURL: websocketUrl,
    reconnectDelay: 5000,
    debug: (str) => console.log(str),
    onConnect: () => {
      console.log("WebSocket Connected!");

      // Subscribe to reports topic
      stompClient.subscribe("/topic/reports", (message) => {
        const reports = JSON.parse(message.body);
        dispatch({
          type: Types.MONITOR_REPORTS_SUCCESS,
          reports: reports,
        });
      });

      // Subscribe to filter acknowledgment queue
      stompClient.subscribe("/user/queue/filter-ack", (message) => {
        dispatch({
          type: Types.FILTER_ACK_RECEIVED,
          payload: JSON.parse(message.body),
        });
      });
    },
    onStompError: (frame) => {
      console.error("Broker error:", frame.headers["message"]);
    },
  });

  stompClient.activate();
};

// Function to send filter request
export const sendFilterRequest = (operatorId) => () => {
  if (!stompClient || !stompClient.connected) {
    console.error("STOMP client is not connected. Retrying connection...");
    return;
  }

  console.log("Attempting to send filter:", operatorId);
  stompClient.publish({
    destination: "/app/filterReports",
    body: JSON.stringify({ operatorId }),
  });
};

// Function to disconnect WebSocket
export const DisconnectWebSocket = () => () => {
  if (stompClient) {
    stompClient.deactivate();
  }
};
