/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import { useDispatch } from "react-redux";
import ReportsFilters from "./ReportsFilters";
import ReportsCharts from "./ReportsCharts";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import InfoCard from "../../../components/InfoCard";
import { formatDate } from "../../../utils/constants";

const Reports = () => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" })
  const [reportType, setReportType] = useState(1);
  const paginationData = { page: 0, size: 100 };

  useEffect(() => {
    dispatch(FetchBusinesses({ ...paginationData }))
  }, [dispatch, paginationData]);

  return (
    <MainLayout title={"Reports"}>
      <ReportsFilters setDateRange={setDateRange} getReportType={setReportType} />
      {dateRange && dateRange?.startDate && dateRange?.endDate ? <div className="w-full flex justify-between mt-6">
        <div className="w-[49%]">
          <InfoCard labels={"From:"} value={formatDate(dateRange.startDate)} hideToolTip={true} />
        </div>
        <div className="w-[49%]">
          <InfoCard labels={"To:"} value={formatDate(dateRange.endDate)} hideToolTip={true} />
        </div>
      </div> : <></>}
      <div className="mt-6">
        <ReportsCharts dateRange={dateRange} reportType={reportType} />
      </div>
    </MainLayout>
  );
};

export default Reports;
