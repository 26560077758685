/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Input, Modal, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as DVLASearch } from "../../../assets/icons/dvla-search.svg";
import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
import { ReactComponent as DVLACalendar } from "../../../assets/icons/dvla-calendar.svg";
import { ReactComponent as DVLAFilter } from "../../../assets/icons/dvla-filter.svg";
import DVLAPlus from "../../../assets/icons/dvla-plus.svg";
import Button from "../../../components/Buttons";
import "../styles.scss";
import DateFilter from "../../../components/DateFilter";
import { VERIFICATION_STATUS } from "../../../utils/misc/general";

const DriversFilters = ({ handleSearch, setDateRange, handleStatusChange }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [activeDateFilter, setActiveDateFilter] = useState(1);
  const [activeDateFilterName, setActiveDateFilterName] = useState("All Time");
  const [verificationStatus, setVerificationStatus] = useState(1);
  const [verificationStatusName, setVerificationStatusName] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    handleSearch(e?.target?.value);
  };

  const hideFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenFilterChange = (newOpen) => {
    setOpenFilter(newOpen);
  };

  const handleFilter = (id) => {
    setActiveDateFilter(id);
    hideFilter();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleVerificationStatus = (id, name) => {
    setVerificationStatus(id);
    handleStatusChange(id === 1 ? "" : name)
    hide();
  };

  useEffect(() => {
    if (VERIFICATION_STATUS && VERIFICATION_STATUS?.length) {
      setVerificationStatusName(
        VERIFICATION_STATUS?.find((c) => c?.id === verificationStatus)?.name
      );
    }
  }, [VERIFICATION_STATUS, verificationStatus]);

  const filterContent = (
    <DateFilter
      activeDateFilter={activeDateFilter}
      setActiveDateFilter={handleFilter}
      setActiveDateFilterName={setActiveDateFilterName}
      setDateRange={setDateRange}
    />
  );

  const content = (
    <div className="w-32">
      {VERIFICATION_STATUS?.map((vs) => (
        <div
          key={vs?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleVerificationStatus(vs?.id, vs?.name)}
        >
          <Checkbox
            checked={vs?.id === verificationStatus}
            className="form-checkbox"
          >
            <div>{vs?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  return (
    <div className="w-full flex">
      <div className="w-3/4">
        <div className="w-full flex gap-x-4">
          <div className="w-4/12">
            <Input
              onChange={handleChange}
              className={"form-field_input_0"}
              placeholder="Search drivers by name, email"
              prefix={<DVLASearch />}
              allowClear
            />
          </div>
          <div className="w-auto">
            <Popover
              content={content}
              trigger="click"
              open={open}
              placement="bottomLeft"
              onOpenChange={handleOpenChange}
            >
              <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px] bg-dark-100">
                <DVLAFilter className="h-4 w-4" />
                <span> Verification Status ({verificationStatusName})</span>
                <DVLAChevronDown className="trend-icon" />
              </div>
            </Popover>
          </div>
          <div className="w-auto">
            <div
              onClick={handleOpenFilterChange}
              className={
                activeDateFilter === 1
                  ? "bg-dark-100 flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px]"
                  : "flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px]"
              }
            >
              <DVLACalendar className="rides-icon h-4 w-4" />
              <span>{activeDateFilterName}</span>
              <DVLAChevronDown className="trend-icon" />
            </div>
            <Modal
              open={openFilter}
              closable={false}
              footer={null}
              onCancel={() => handleOpenFilterChange(false)}
              className={"filter-modal"}
            >
              {filterContent}
            </Modal>
          </div>
        </div>
      </div>
      <div className="w-1/4 flex justify-end">
        <Button
          css={"py-[9px] rounded-md btn-style"}
          state={"PRIMARY"}
          text={"Add driver"}
          type={"button"}
          icon={DVLAPlus}
        />
      </div>
    </div>
  );
};

export default DriversFilters;
