import { exportPassengers, getPassengers, getPassenger } from "../_api/index";

export const Types = {
  FETCH_PASSENGERS_START: "FETCH_PASSENGERS_START",
  FETCH_PASSENGERS_END: "FETCH_PASSENGERS_END",
  FETCH_PASSENGERS_SUCCESS: "FETCH_PASSENGERS_SUCCESS",

  FETCH_PASSENGER_START: "FETCH_PASSENGER_START",
  FETCH_PASSENGER_END: "FETCH_PASSENGER_END",
  FETCH_PASSENGER_SUCCESS: "FETCH_PASSENGER_SUCCESS",

  EXPORT_PASSENGERS_START: "EXPORT_PASSENGERS_START",
  EXPORT_PASSENGERS_END: "EXPORT_PASSENGERS_END",
  EXPORT_PASSENGERS_SUCCESS: "EXPORT_PASSENGERS_SUCCESS",
};

export const FetchPassengers = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_PASSENGERS_START });
  return new Promise((resolve, reject) => {
    getPassengers(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_PASSENGERS_SUCCESS,
          passengers: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_PASSENGERS_END });
        reject(err);
      });
  });
};

export const FetchPassenger = (passengerId) => (dispatch) => {
  dispatch({ type: Types.FETCH_PASSENGER_START });
  return new Promise((resolve, reject) => {
    getPassenger(passengerId)
      .then((res) => {
        dispatch({
          type: Types.FETCH_PASSENGER_SUCCESS,
          passenger: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_PASSENGER_END });
        reject(err);
      });
  });
};

export const ExportPassengers = (filter) => (dispatch) => {
  dispatch({ type: Types.EXPORT_PASSENGERS_START });
  return new Promise((resolve, reject) => {
    exportPassengers(filter)
      .then((res) => {
        dispatch({
          type: Types.EXPORT_PASSENGERS_SUCCESS,
          exportPassengers: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.EXPORT_PASSENGERS_END });
        reject(err);
      });
  });
};
