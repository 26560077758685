/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Spin } from "antd";

const Loader = ({ loading }) => {
  return (
    loading ? <div className="w-full h-[200px] flex justify-center items-center">
      <Spin size={"medium"} className={"spinner"} />
    </div> : <></>
  );
};

export default Loader;
