/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import SimpleChart from "./SimpleChart";
import DoughnutChart from "./DoughnutChart";
import BarChart from "./BarChart";
import MonitorFilters from "./MonitorFilters";

const Manage = ({ reports, setPartnerId }) => {
  const [simpleReports, setSimpleReports] = useState([]);
  const [otherReports, setOtherReports] = useState([]);

  const separateReports = (reports) => {
    const simpleReports = [];
    const otherReports = [];

    reports.forEach(report => {
      if (report.type === "simple") {
        simpleReports.push(report);
      } else {
        otherReports.push(report);
      }
    });

    return {
      simple: simpleReports,
      others: otherReports
    };
  }

  useEffect(() => {
    const r = separateReports(reports)
    setSimpleReports(r.simple);
    setOtherReports(r.others);
  }, [reports])

  return (
    <div className="w-full flex flex-col gap-y-6">
      {simpleReports.length > 0 ? <div className="w-full monitor-card bg-white p-6 grid grid-cols-4 gap-x-6 gap-y-3">
        <div className="col-span-4">
          <div className="flex w-full justify-between mb-4 items-center">
            <p className="text-sm-headline font-bold text-left">Trip Counts</p>
            <MonitorFilters setPartnerId={setPartnerId} />
          </div>
          <div className="border-b-[1px] mb-2 border-[#EBEBEB]"></div>
        </div>
        {simpleReports.map((sr, index) => <SimpleChart key={sr.title + index} label={sr.title} value={sr.value} formatType={sr.formatType} />)}
      </div> : <></>}

      {otherReports.length > 0 ? <div className="w-full grid grid-cols-2 gap-6">
        {otherReports.map((or, index) => or?.type === "piechart" ? <DoughnutChart key={or.title + index} report={or} /> : <BarChart key={or.title + index} report={or} />)}
      </div> : <></>}
    </div>
  );
};

export default Manage;
