import Http from "../../lib/api";

// authentication
export const login = (req) => {
  return Http.post("/authenticate", req);
};

export const partnerLogin = (req, id) => {
  return Http.post(`/partners/authenticate`, req);
};

export const activateUser = (params) => {
  return Http.get(
    `/platform-users/activate-user?email=${params?.email}&activationCode=${params?.activationCode}`
  );
};

export const getAllPartners = () => {
  return Http.get("/businesses");
};

export const changePassword = (req) => {
  return Http.post("/platform-users/change-password", req);
};

export const forgotPassword = (email) => {
  return Http.get(`/platform-users/reset-password?userEmail=${email}`);
};

export const resetPassword = ({ email, otp, password }) => {
  return Http.patch(
    `platform-users/reset-password/confirm?userEmail=${email}&password=${password}&otp=${otp}`
  );
};

// businesses
export const getBusinesses = (params) => {
  return Http.get(
    `/businesses?page=${params?.page ?? 0}&size=${
      params?.size ?? 100
    }&searchQuery=${params?.searchQuery ?? ""}&startDate=${
      params?.startDate ?? ""
    }&endDate=${params?.endDate ?? ""}`
  );
};

export const getBusiness = (id) => {
  return Http.get(`/businesses/${id}`);
};

export const addBusiness = (req) => {
  return Http.post("/businesses", req);
};

// rides
export const getAdminRides = (params) => {
  return Http.get(
    `/admin/rides?page=${params?.page ?? 0}&size=${
      params?.size ?? 100
    }&businessId=${params?.company ?? ""}&searchQuery=${
      params?.searchQuery ?? ""
    }&startDate=${params?.startDate ?? ""}&endDate=${params?.endDate ?? ""}`
  );
};

export const getBusinessRides = (id, params) => {
  // return Http.get(
  //   `/rides/${id}?page=${params?.page ?? 0}&size=${
  //     params?.size ?? 100
  //   }&searchQuery=${params?.searchQuery ?? ""}&startDate=${
  //     params?.startDate ?? ""
  //   }&endDate=${params?.endDate ?? ""}`
  // );
  return Http.get(
    `/rides/businesses/${id}?page=${params?.page ?? 0}&size=${
      params?.size ?? 100
    }&searchQuery=${params?.searchQuery ?? ""}&startDate=${
      params?.startDate ?? ""
    }&endDate=${params?.endDate ?? ""}`
  );
};

export const getBusinessRidesMonthlyTrends = (id, params) => {
  return Http.get(
    id
      ? `/rides/trends/monthly?businesses=${id}&page=${
          params?.page ?? 0
        }&size=${params?.size ?? 100}&startDate=${
          params?.startDate ? params?.startDate.split(" ")[0] : ""
        }&endDate=${params?.endDate ? params?.endDate.split(" ")[0] : ""}`
      : `/rides/trends/monthly?page=${params?.page ?? 0}&size=${
          params?.size ?? 100
        }&startDate=${
          params?.startDate ? params?.startDate.split(" ")[0] : ""
        }&endDate=${params?.endDate ? params?.endDate.split(" ")[0] : ""}`
  );
};

export const getBusinessRidesDailyTrends = (id, params) => {
  return Http.get(
    id
      ? `/rides/trends/daily?businesses=${id}&page=${params?.page ?? 0}&size=${
          params?.size ?? 100
        }&startDate=${
          params?.startDate ? params?.startDate.split(" ")[0] : ""
        }&endDate=${params?.endDate ? params?.endDate.split(" ")[0] : ""}`
      : `/rides/trends/daily?page=${params?.page ?? 0}&size=${
          params?.size ?? 100
        }&startDate=${
          params?.startDate ? params?.startDate.split(" ")[0] : ""
        }&endDate=${params?.endDate ? params?.endDate.split(" ")[0] : ""}`
  );
};

export const getRide = (id) => {
  return Http.get(`/rides/${id}`);
};

export const exportRides = (filter) => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return user?.isSiteAdmin
    ? Http.get("/rides/download", { params })
    : Http.get(`/rides/businesses/${user?.business?.id}/download`, { params });
};

export const uploadRideBatch = (formData, config, note) => {
  return Http.post(`/bulk-load?note=${note}`, formData, config);
};

export const getRideBatches = (filter) => {
  return Http.get(
    filter?.name
      ? `/ride-batches?page=${filter?.page ?? 0}&size=${
          filter?.size ?? 100
        }&businessId=${filter?.partnerId === 0 ? "" : filter?.partnerId}&name=${
          filter?.name ?? ""
        }`
      : `/ride-batches?page=${filter?.page ?? 0}&size=${
          filter?.size ?? 100
        }&businessId=${filter?.partnerId === 0 ? "" : filter?.partnerId}`
  );
};

export const getRideSummary = (filter) => {
  return Http.get(
    filter?.partnerId
      ? `/rides/summary?businessId=${filter?.partnerId}&page=${
          filter?.page ?? 0
        }&size=${filter?.size ?? 100}&startDate=${
          filter?.startDate ? filter?.startDate.split(" ")[0] : ""
        }&endDate=${filter?.endDate ? filter?.endDate.split(" ")[0] : ""}`
      : `/rides/summary?page=${filter?.page ?? 0}&size=${
          filter?.size ?? 100
        }&startDate=${
          filter?.startDate ? filter?.startDate.split(" ")[0] : ""
        }&endDate=${filter?.endDate ? filter?.endDate.split(" ")[0] : ""}`
  );
};

// vehicles
export const getVehicles = (params) => {
  return Http.get(
    `/vehicles?page=${params?.page ?? 0}&size=${
      params?.size ?? 100
    }&vehicleId=${params?.vehicleId ?? ""}&searchQuery=${
      params?.searchQuery ?? ""
    }&startDate=${params?.startDate ?? ""}&endDate=${params?.endDate ?? ""}`
  );
};

export const getVehicle = (id) => {
  return Http.get(`/vehicles/${id}`);
};

export const updateVehicle = (payload) => {
  return Http.patch(`/vehicles`, payload);
};

export const exportVehicles = (filter) => {
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return Http.get("/vehicles/download", { params });
};

// drivers
export const getDrivers = (params) => {
  return Http.get(
    `/drivers?page=${params?.page ?? 0}&size=${params?.size ?? 100}&driverId=${
      params?.driverId ?? ""
    }&searchQuery=${params?.searchQuery ?? ""}&startDate=${
      params?.startDate ?? ""
    }&endDate=${params?.endDate ?? ""}&status=${params?.status ?? ""}`
  );
};

export const getDriver = (id) => {
  return Http.get(`/drivers/${id}`);
};

export const updateDriver = (payload) => {
  return Http.patch(`/drivers`, payload);
};

export const uploadDocument = (formData, config, id) => {
  return Http.post(`/documents/drivers/${id}`, formData, config);
};

export const exportDrivers = (filter) => {
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return Http.get("/drivers/download", { params });
};

// users
export const getCurrentUser = () => {
  return Http.get("/logged-in-details");
};

export const getUsers = (filter) => {
  return Http.get(
    filter?.name
      ? `/platform-users?page=${filter?.page ?? 0}&size=${
          filter?.size ?? 100
        }&businessId=${filter?.partnerId === 0 ? "" : filter?.partnerId}&name=${
          filter?.name ?? ""
        }`
      : `/platform-users?page=${filter?.page ?? 0}&size=${
          filter?.size ?? 100
        }&businessId=${filter?.partnerId === 0 ? "" : filter?.partnerId}`
  );
};

export const getPartnerUsers = (filter) => {
  return Http.get(
    filter?.name
      ? `/partner-users?page=${filter?.page ?? 0}&size=${
          filter?.size ?? 100
        }&name=${filter?.name ?? ""}`
      : `/partner-users?page=${filter?.page ?? 0}&size=${filter?.size ?? 100}`
  );
};

export const inviteUser = (req) => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  return Http.post(
    user?.isSiteAdmin ? "/platform-users/invite" : "/partner-users/invite",
    req
  );
};

export const invitePartnerUser = (req, id) => {
  return Http.post(
    id ? `/partner-users/invite?businessId=${id}` : "/partner-users/invite",
    req
  );
};

// invoices
export const getInvoices = (params) => {
  return Http.get(
    `/invoices?page=${params?.page ?? 0}&size=${
      params?.size ?? 100
    }&businessId=${params?.company ?? ""}&searchQuery=${
      params?.searchQuery ?? ""
    }&startDate=${params?.startDate ?? ""}&endDate=${
      params?.endDate ?? ""
    }&status=${params?.status ?? ""}`
  );
};

export const getInvoice = (id) => {
  return Http.get(`/invoices/${id}`);
};

export const getInvoiceStats = (params) => {
  return Http.get(
    `/stats/invoices?businessId=${params?.company ?? ""}&startDate=${
      params?.startDate ?? ""
    }&endDate=${params?.endDate ?? ""}&status=${params?.status ?? ""}`
  );
};

export const createInvoice = (req) => {
  return Http.post(`/invoices`, req);
};

export const updateInvoiceStatus = (id, status) => {
  return Http.patch(`/invoices/${id}/status/${status}`);
};

// stats
export const getAdminAllTimeRides = (params) => {
  return Http.get(
    `/admin/all-time-rides?businessId=${params?.company ?? ""}&startDate=${
      params?.startDate ?? ""
    }&endDate=${params?.endDate ?? ""}`
  );
};

export const getBusinessSumTotalRides = (id, params) => {
  return Http.get(
    `/sum-total-rides/${id}?startDate=${params?.startDate ?? ""}&endDate=${
      params?.endDate ?? ""
    }`
  );
};

export const getForceAggregate = (params) => {
  return Http.get(
    `/force-aggregate?startDate=${params?.startDate ?? ""}&endDate=${
      params?.endDate ?? ""
    }`
  );
};

export const getBusinessTotalRides = (id, params) => {
  return Http.get(
    `/find-total-rides/${id}?startDate=${params?.startDate ?? ""}&endDate=${
      params?.endDate ?? ""
    }`
  );
};

export const getBusinessRideStats = (id, params) => {
  return Http.get(
    `/find-ride-stats/${id}?startDate=${params?.startDate ?? ""}&endDate=${
      params?.endDate ?? ""
    }`
  );
};

export const getBusinessRideAggregate = (id, params) => {
  return Http.get(
    `/find-total-aggregate/${id}?startDate=${params?.startDate ?? ""}&endDate=${
      params?.endDate ?? ""
    }`
  );
};

// flags
export const getFlags = (params) => {
  return Http.get(
    `/flags?page=${params?.page ?? 0}&size=${params?.size ?? 100}&driverId=${
      params?.driverId ?? ""
    }&flagId=${params?.flagId ?? ""}&flagType=${
      params?.flagType ?? ""
    }&startDate=${params?.startDate ?? ""}&endDate=${params?.endDate ?? ""}`
  );
};

export const createFlag = (req, driverId) => {
  return Http.post(`/flags/drivers/${driverId}`, req);
};

export const updateFlag = (req) => {
  return Http.patch(`/flags`, req);
};

export const getFlag = (flagId) => {
  return Http.get(`/flags/${flagId}`);
};

export const deleteFlag = (flagId) => {
  return Http.delete(`/flags/${flagId}/remove`);
};

// passengers
export const getPassengers = (filter) => {
  return Http.get(
    filter?.query
      ? `/passengers?page=${filter?.page ?? 0}&size=${
          filter?.size ?? 100
        }&query=${filter?.query ?? ""}`
      : `/passengers?page=${filter?.page ?? 0}&size=${filter?.size ?? 100}`
  );
};

export const getPassenger = (passengerId) => {
  return Http.get(`/passengers/${passengerId}`);
};

export const exportPassengers = (filter) => {
  return Http.get(
    `/passengers/download?page=${filter?.page ?? 0}&size=${filter?.size ?? 100}`
  );
};

// reports
export const getComplianceReports = (filter) => {
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return Http.get("/rides/compliance-summary", { params });
};

export const getTripSummaryReports = (filter) => {
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return Http.get("/rides/summary", { params });
};

export const getHighestTripDestinationsReports = (filter) => {
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return Http.get("/rides/trips-destination", { params });
};

export const getHighestTripOriginsReports = (filter) => {
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return Http.get("/rides/trips-origin", { params });
};

// violations
export const getViolations = (filter) => {
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return Http.get("/violations", { params });
};

export const exportViolations = (filter) => {
  const params = Object.fromEntries(
    Object.entries(filter).filter(
      ([_, value]) => value !== "" && value !== null && value !== undefined
    )
  );
  return Http.get("/violations/download", { params });
};
