/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, notification } from "antd";
import AuthLayout from "../../../layouts/AuthLayout";
import AuthHeading from "../AuthHeading";
import DVLAPoweredBy from "../../../assets/imgs/dvla-powered-by.png";
import { Formik } from "formik";
import * as yup from "yup";
import PasswordField from "../../../components/Inputs/PasswordField";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import Button from "../../../components/Buttons";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../../redux/actions/auths.action";
import { FetchCurrentUser } from "../../../redux/actions/users.action";

const Login = () => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.auths.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    username: "",
    password: "",
    tAndC: true,
  });

  const login = (values) => {
    dispatch(LoginUser(values))
      .then(async (res) => {
        const accessToken = res.data.jwt;
        const refreshToken = res.data.refreshToken;
        localStorage.setItem("dvla_token", accessToken);
        localStorage.setItem("dvla_refresh_token", refreshToken);
        localStorage.setItem("dvla_login", values?.username);
        notification.success({
          message: "Success",
          description: "Login Successfully!",
        });
        await dispatch(FetchCurrentUser()).then((res) => {
          localStorage.setItem("dvla_user", JSON.stringify(res.data.object));
        });
        navigate("/dashboard");
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description:
            err?.response?.status === 403
              ? "Invalid username or password"
              : "An error has ocurred, please try again later",
        });
      });
  };

  useEffect(() => {
    const username = localStorage.getItem("dvla_login");
    if (username !== null && username !== "") {
      form.setFieldsValue({ ...values, username });
      setValues({ ...values, username });
    }
  }, []);
  return (
    <AuthLayout>
      <AuthHeading backButtonName={"Back to Home"} location={"PREV"} />
      <div className="px-4 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl w-full">
        <div className="text-left auth-shadow p-8 rounded-lg border-primary-500 gap-y-6 flex flex-col">
          <Formik
            initialValues={values}
            onSubmit={login}
            enableReinitialize={true}
            validationSchema={validator}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onFinish={handleSubmit} form={form}>
                <div className={"mb-4"}>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <FloatLabel
                      label="Enter Email"
                      value={values?.username || ""}
                    >
                      <Form.Item
                        name={"username"}
                        help={
                          touched.username && errors.username
                            ? errors.username
                            : ""
                        }
                        validateStatus={
                          touched.username && errors.username
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type={"email"}
                          className={"form-field_input_2"}
                          value={values?.username || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </FloatLabel>
                  </FieldContainer>
                </div>
                <div className={"mb-4"}>
                  <div className="text-right">
                    <NavLink
                      className={"text-labels text-primary-500 font-medium"}
                      to={"/forgot-password"}
                    >
                      Forgot Password?
                    </NavLink>
                  </div>
                  <PasswordField
                    iconPlacementCss={"iconCss"}
                  >
                    <FloatLabel label="Password" value={values?.password || ""}>
                      <Form.Item
                        name={"password"}
                        help={
                          touched.password && errors.password
                            ? errors.password
                            : ""
                        }
                        validateStatus={
                          touched.password && errors.password
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type={"password"}
                          className={"form-field_input_2"}
                          value={values?.password || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </FloatLabel>
                  </PasswordField>
                </div>
                <div className={"mb-4"}>
                  <Form.Item
                    name={"tAndC"}
                    help={touched.tAndC && errors.tAndC ? errors.tAndC : ""}
                    validateStatus={
                      touched.tAndC && errors.tAndC ? "error" : "success"
                    }
                  >
                    <Checkbox
                      checked={values?.tAndC || false}
                      onChange={handleChange}
                      className="form-checkbox"
                    >
                      <span className="text-gray-500">
                        I agree with DriveIQ’s
                      </span>
                      <span className="text-primary-500 font-semibold cursor-pointer">
                        {" "}
                        Terms of use{" "}
                      </span>
                      <span className="text-gray-500">and</span>
                      <span className="text-primary-500 font-semibold cursor-pointer">
                        {" "}
                        Privacy Policy
                      </span>
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className={"flex justify-center"}>
                  <Button
                    css={"w-full"}
                    state={"PRIMARY"}
                    text={"Login to account"}
                    type={"submit"}
                    loading={loader}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <img
          className={"h-4 mt-4"}
          src={DVLAPoweredBy}
          alt={"dvla-powered-by"}
        />
      </div>
    </AuthLayout>
  );
};

const validator = yup.object().shape({
  username: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
  tAndC: yup
    .boolean()
    .oneOf([true], "Terms of use and Privacy Policy is required"),
});

export default Login;
