import { exportViolations, getViolations } from "../_api/index";

export const Types = {
  FETCH_VIOLATIONS_START: "FETCH_VIOLATIONS_START",
  FETCH_VIOLATIONS_END: "FETCH_VIOLATIONS_END",
  FETCH_VIOLATIONS_SUCCESS: "FETCH_VIOLATIONS_SUCCESS",

  EXPORT_VIOLATIONS_START: "EXPORT_VIOLATIONS_START",
  EXPORT_VIOLATIONS_SUCCESS: "EXPORT_VIOLATIONS_SUCCESS",
  EXPORT_VIOLATIONS_END: "EXPORT_VIOLATIONS_END",
};

export const FetchViolations = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_VIOLATIONS_START });
  return new Promise((resolve, reject) => {
    getViolations(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_VIOLATIONS_SUCCESS,
          violations: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_VIOLATIONS_END });
        reject(err);
      });
  });
};

export const ExportViolations = (param) => (dispatch) => {
  dispatch({ type: Types.EXPORT_VIOLATIONS_START });
  return new Promise((resolve, reject) => {
    exportViolations(param)
      .then((res) => {
        dispatch({
          type: Types.EXPORT_VIOLATIONS_SUCCESS,
          export_violations: res?.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.EXPORT_VIOLATIONS_END });
        reject(err);
      });
  });
};
