/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Input, Modal, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as DVLASearch } from "../../../assets/icons/dvla-search.svg";
import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
import { ReactComponent as DVLACalendar } from "../../../assets/icons/dvla-calendar.svg";
import { ReactComponent as DVLAFilter } from "../../../assets/icons/dvla-filter.svg";
import DVLAExport from "../../../assets/icons/dvla-export.svg";
import Button from "../../../components/Buttons";
import "../styles.scss";
import DateFilter from "../../../components/DateFilter";
import { STATUSES } from "../../../utils/misc/general";
import { useSelector } from "react-redux";

const InvoiceFilters = ({ handleSearch, setDateRange, getCompanyId, handleStatusChange }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [activeDateFilter, setActiveDateFilter] = useState(1);
  const [activeDateFilterName, setActiveDateFilterName] = useState("All Time");
  const [company, setCompany] = useState(0);
  const { businesses } = useSelector((state) => state?.businesses);
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(1);
  const [statusName, setStatusName] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const user = JSON.parse(localStorage.getItem("dvla_user"));

  const handleChange = (e) => {
    handleSearch(e?.target?.value);
  };

  const hideFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenFilterChange = (newOpen) => {
    setOpenFilter(newOpen);
  };

  const handleFilter = (id) => {
    setActiveDateFilter(id);
    hideFilter();
  };

  const hideStatus = () => {
    setOpenStatus(false);
  };

  const handleOpenStatusChange = (newOpen) => {
    setOpenStatus(newOpen);
  };

  const handleStatus = (id, name) => {
    setStatus(id);
    handleStatusChange(id === 1 ? "" : name)
    hideStatus();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleCompany = (id) => {
    setCompany(id);
    getCompanyId(id);
    hide();
  };

  useEffect(() => {
    if (businesses?.content && businesses?.content?.length) {
      setCompanyName(businesses?.content?.find((c) => c?.id === company)?.name);
    }
  }, [companies, company]);

  useEffect(() => {
    const all = { id: 0, name: "All" };
    let content = businesses?.content;
    if (content?.length) {
      content.unshift(all);
      setCompanies(content);
    } else {
      content = [all];
      setCompanies(content);
    }
  }, [businesses]);

  useEffect(() => {
    if (STATUSES && STATUSES?.length) {
      setStatusName(STATUSES?.find((s) => s?.id === status)?.name);
    }
  }, [STATUSES, status]);

  const filterContent = (
    <DateFilter
      activeDateFilter={activeDateFilter}
      setActiveDateFilter={handleFilter}
      setActiveDateFilterName={setActiveDateFilterName}
      setDateRange={setDateRange}
    />
  );

  const content = (
    <div className="w-32">
      {companies?.map((c) => (
        <div
          key={c?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleCompany(c?.id)}
        >
          <Checkbox checked={c?.id === company} className="form-checkbox">
            <div>{c?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  const statusContent = (
    <div className="w-32">
      {STATUSES?.map((s) => (
        <div
          key={s?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleStatus(s?.id, s?.name)}
        >
          <Checkbox checked={s?.id === status} className="form-checkbox">
            <div>{s?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  return (
    <div className="w-full flex">
      <div className="w-3/4">
        <div className="w-full flex gap-x-4">
          <div className="w-4/12">
            <Input
              onChange={handleChange}
              className={"form-field_input_0"}
              placeholder="Search invoice name, compan"
              prefix={<DVLASearch />}
              allowClear
            />
          </div>
          {user?.isSiteAdmin && <div className="w-auto">
            <Popover
              content={content}
              trigger="click"
              open={open}
              placement="bottomLeft"
              onOpenChange={handleOpenChange}
            >
              <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px] bg-dark-100">
                <DVLAFilter className="h-4 w-4" />
                <span> Company ({companyName})</span>
                <DVLAChevronDown className="trend-icon" />
              </div>
            </Popover>
          </div>}
          <div className="w-auto">
            <Popover
              content={statusContent}
              trigger="click"
              open={openStatus}
              placement="bottomLeft"
              onOpenChange={handleOpenStatusChange}
            >
              <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px] bg-dark-100">
                <DVLAFilter className="h-4 w-4" />
                <span> Status ({statusName})</span>
                <DVLAChevronDown className="trend-icon" />
              </div>
            </Popover>
          </div>
          <div className="w-auto">
            <div
              onClick={handleOpenFilterChange}
              className={
                activeDateFilter === 1
                  ? "bg-dark-100 flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px]"
                  : "flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px]"
              }
            >
              <DVLACalendar className="rides-icon h-4 w-4" />
              <span>{activeDateFilterName}</span>
              <DVLAChevronDown className="trend-icon" />
            </div>
            <Modal
              open={openFilter}
              closable={false}
              footer={null}
              onCancel={() => handleOpenFilterChange(false)}
              className={"filter-modal"}
            >
              {filterContent}
            </Modal>
          </div>
        </div>
      </div>
      <div className="w-1/4 flex justify-end">
        <Button
          css={"py-[9px] rounded-md btn-style"}
          state={"PRIMARY"}
          text={"Export Data"}
          type={"button"}
          icon={DVLAExport}
        />
      </div>
    </div>
  );
};

export default InvoiceFilters;
