/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import VerifyDriverAndVehicle from "../Shared/VerifyDriverAndVehicle";
import { useDispatch } from "react-redux";
import { ExportVehicles, FetchVehicles } from "../../../redux/actions/vehicles.action";
import { ExportDrivers, FetchDrivers } from "../../../redux/actions/drivers.action";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import EmailPrompt from "../../../components/EmailPrompt";
import { notification } from "antd";

const DVLADatabase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ searchQuery: "" });
  const [open, setOpen] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 5,
  });
  const [activeTab, setActiveTab] = useState(1)

  const handleDriver = (id) => {
    navigate(`/dvla-database/driver/${id}`);
  };

  const handleVehicle = (id) => {
    navigate(`/dvla-database/vehicle/${id}`);
  };

  useEffect(() => {
    activeTab === 1 && dispatch(FetchDrivers({ ...paginationData, ...query, }));
    activeTab === 2 && dispatch(FetchVehicles({ ...paginationData, ...query, }));
  }, [dispatch, query, activeTab, paginationData])

  const handleSearch = (value, tab) => {
    setActiveTab(tab);
    performSearch(value);
  };

  const performSearch = debounce((value) => {
    if (value.length > 0) {
      setQuery({ searchQuery: value });
    } else {
      setQuery({ searchQuery: "" });
    }
  }, 700);

  const exportData = () => {
    setOpen(true);
  }

  const handleCallBack = (email) => {
    if (activeTab === 1) {
      dispatch(ExportDrivers({ ...paginationData, ...query, email })).then(async (res) => {
        setOpen(false);
        notification.success({
          message: "Success",
          description: "Download request received. You will receive an email when the report is ready.",
        });
      }).catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
    }

    if (activeTab === 2) {
      dispatch(ExportVehicles({ ...paginationData, ...query, email })).then(async (res) => {
        setOpen(false);
        notification.success({
          message: "Success",
          description: "Download request received. You will receive an email when the report is ready.",
        });
      }).catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
    }
  }

  return (
    <MainLayout title={"DVLA Database"}>
      <VerifyDriverAndVehicle
        getDriver={handleDriver}
        getVehicle={handleVehicle}
        handleSearch={handleSearch}
        handleActiveTab={setActiveTab}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        exportData={exportData}
      />
      <EmailPrompt open={open} setOpen={setOpen} callBack={handleCallBack} />
    </MainLayout>
  );
};

export default DVLADatabase;
