import {
  exportVehicles,
  getVehicle,
  getVehicles,
  updateVehicle,
} from "../_api/index";

export const Types = {
  ALL_VEHICLES_START: "ALL_VEHICLES_START",
  ALL_VEHICLES_SUCCESS: "ALL_VEHICLES_SUCCESS",
  ALL_VEHICLES_END: "ALL_VEHICLES_END",

  VEHICLE_START: "VEHICLE_START",
  VEHICLE_SUCCESS: "VEHICLE_SUCCESS",
  VEHICLE_END: "VEHICLE_END",

  UPDATE_VEHICLE_START: "UPDATE_VEHICLE_START",
  UPDATE_VEHICLE_SUCCESS: "UPDATE_VEHICLE_SUCCESS",
  UPDATE_VEHICLE_END: "UPDATE_VEHICLE_END",

  EXPORT_VEHICLES_START: "EXPORT_VEHICLES_START",
  EXPORT_VEHICLES_SUCCESS: "EXPORT_VEHICLES_SUCCESS",
  EXPORT_VEHICLES_END: "EXPORT_VEHICLES_END",
};

export const FetchVehicles = (param) => (dispatch) => {
  dispatch({ type: Types.ALL_VEHICLES_START });
  return new Promise((resolve, reject) => {
    getVehicles(param)
      .then((res) => {
        dispatch({
          type: Types.ALL_VEHICLES_SUCCESS,
          all_vehicles: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ALL_VEHICLES_END });
        reject(err);
      });
  });
};

export const FetchVehicle = (id) => (dispatch) => {
  dispatch({ type: Types.VEHICLE_START });
  return new Promise((resolve, reject) => {
    getVehicle(id)
      .then((res) => {
        dispatch({
          type: Types.VEHICLE_SUCCESS,
          vehicle: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.VEHICLE_END });
        reject(err);
      });
  });
};

export const UpdateVehicleProfile = (payload) => (dispatch) => {
  dispatch({ type: Types.UPDATE_VEHICLE_START });
  return new Promise((resolve, reject) => {
    updateVehicle(payload)
      .then((res) => {
        dispatch({
          type: Types.UPDATE_VEHICLE_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.UPDATE_VEHICLE_END });
        reject(err);
      });
  });
};

export const ExportVehicles = (param) => (dispatch) => {
  dispatch({ type: Types.EXPORT_VEHICLES_START });
  return new Promise((resolve, reject) => {
    exportVehicles(param)
      .then((res) => {
        dispatch({
          type: Types.EXPORT_VEHICLES_SUCCESS,
          export_vehicles: res?.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.EXPORT_VEHICLES_END });
        reject(err);
      });
  });
};
