/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from "antd";
import React from "react";
import { ReactComponent as DVLASearch } from "../../../assets/icons/dvla-search.svg";
import "../styles.scss";

const PassengersFilters = ({ handleSearch }) => {

  const handleChange = (e) => {
    handleSearch(e?.target?.value);
  };

  return (
    <div className="w-full flex">
      <div className="w-3/4">
        <div className="w-full flex gap-x-4 justify-between">
          <div className="w-5/12">
            <Input
              onChange={handleChange}
              className={"form-field_input_0"}
              placeholder="Search passengers"
              prefix={<DVLASearch />}
              allowClear
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassengersFilters;
