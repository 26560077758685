/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../layouts/AuthLayout";
import "../styles.scss";
import { notification, Spin } from "antd";
import { useDispatch } from "react-redux";
import { ActivateUser } from "../../../redux/actions/auths.action";
import Button from "../../../components/Buttons";

const Activation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const activationCode = urlParams.get("activationCode");

  useEffect(() => {
    if (!email && !activationCode) {
      navigate("/");
    } else {
      setLoading(true);
      dispatch(ActivateUser({ email, activationCode }))
        .then(async (res) => {
          setLoading(false);
          setSuccess(true);
          notification.success({
            message: "Success",
            description: "Account Activated Successfully!",
          });
        })
        .catch((err) => {
          setLoading(false);
          setSuccess(false);
          notification.error({
            message: "Error",
            description: err.response.data.errorMsg
              ? err.response.data.errorMsg
              : "An error has ocurred, please try again later",
          });
        });
    }
  }, []);
  return (
    <AuthLayout>
      <div
        className={
          "h-full justify-center flex flex-col w-full pt-20 pb-10 px-8 sm:px-16 lg:px-10 xl:px-16 relative"
        }
      >
        {loading && (
          <div>
            <p className={"text-labels mb-2 text-warning"}>
              Your account is been verified
            </p>
            <Spin size={"large"} className={"spinner"} />
          </div>
        )}

        {!loading && success && (
          <div>
            <p className={"text-labels mb-2 text-primary-500"}>
              Congratulations! Your account has been verified.
            </p>
            <div className={"mb-6 mt-10 flex justify-center"}>
              <Button
                css={"py-3 lg:text-labels"}
                state={"PRIMARY"}
                text={"Login"}
                type={"button"}
                onClick={() => navigate("/", { replace: true })}
              />
            </div>
          </div>
        )}

        {!loading && !success && (
          <div>
            <p className={"text-labels mb-2 text-error-main"}>
              Something went wrong. Please check the URL and try again
            </p>
          </div>
        )}
      </div>
    </AuthLayout>
  );
};

export default Activation;
