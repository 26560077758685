/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from "antd";
import React from "react";
import { ReactComponent as DVLASearch } from "../../../assets/icons/dvla-search.svg";
import DVLAInvite from "../../../assets/imgs/dvla-invite.png";
import Button from "../../../components/Buttons";
import "../styles.scss";
// import DateFilter from "../../../components/DateFilter/DateFilter";
// import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
// import { ReactComponent as DVLACalendar } from "../../../assets/icons/dvla-calendar.svg";
import { useNavigate } from "react-router-dom";

const CompaniesFilters = ({ handleSearch, setDateRange }) => {
  // const [openFilter, setOpenFilter] = useState(false);
  // const [activeDateFilter, setActiveDateFilter] = useState(1);
  // const [activeDateFilterName, setActiveDateFilterName] = useState("All Time");
  const navigate = useNavigate();

  const handleChange = (e) => {
    handleSearch(e?.target?.value);
  };

  // const hideFilter = () => {
  //   setOpenFilter(false);
  // };

  // const handleOpenFilterChange = (newOpen) => {
  //   setOpenFilter(newOpen);
  // };

  // const handleFilter = (id) => {
  //   setActiveDateFilter(id);
  //   hideFilter();
  // };

  const addNewCompany = () => {
    navigate("/partners/new")
  }

  // const filterContent = (
  //   <DateFilter
  //     activeDateFilter={activeDateFilter}
  //     setActiveDateFilter={handleFilter}
  //     setActiveDateFilterName={setActiveDateFilterName}
  //     setDateRange={setDateRange}
  //   />
  // );

  return (
    <div className="w-full flex">
      <div className="w-3/4">
        <div className="w-full flex gap-x-4">
          <div className="w-6/12">
            <Input
              onChange={handleChange}
              className={"form-field_input_0"}
              placeholder="Search company"
              prefix={<DVLASearch />}
              allowClear
            />
          </div>
          {/* <div className="w-auto">
            <div
              onClick={handleOpenFilterChange}
              className={
                activeDateFilter === 1
                  ? "bg-dark-100 flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px]"
                  : "flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px]"
              }
            >
              <DVLACalendar className="rides-icon h-4 w-4" />
              <span>{activeDateFilterName}</span>
              <DVLAChevronDown className="trend-icon" />
            </div>
            <Modal
              open={openFilter}
              closable={false}
              footer={null}
              onCancel={() => handleOpenFilterChange(false)}
              className={"filter-modal"}
            >
              {filterContent}
            </Modal>
          </div> */}
        </div>
      </div>
      <div className="w-1/4 flex justify-end">
        <Button
          css={"py-[9px] rounded-md lg:text-[10px] text-[10px] xl:px-[14px] px-2"}
          state={"PRIMARY"}
          text={"New Partner"}
          type={"button"}
          icon={DVLAInvite}
          onClick={addNewCompany}
        />
      </div>
    </div>
  );
};

export default CompaniesFilters;
