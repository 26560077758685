/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { FetchHighestTripDestinationsReports } from "../../../redux/actions/reports.action";
import { notification } from "antd";
import Loader from "../../../components/Loader";
import ReactECharts from "echarts-for-react";
import { numberWithCommas } from "../../../utils/constants";

const options = {
  grid: { top: 8, right: 8, bottom: 70, left: 70 },
  tooltip: {
    trigger: "axis",
    formatter: (params) =>
      params
        .map((item) => `${item.name}: ${numberWithCommas(item.value)}`)
        .join("<br/>"),
  },
  xAxis: {
    type: "category",
    data: [],
    axisLabel: {
      rotate: 25,
    },
  },
  yAxis: {
    type: "value",
    name: "Trip Count",
    nameLocation: "middle",
    nameGap: 50,
    nameTextStyle: {
      fontWeight: "bold",
      fontSize: 14,
    },
    axisLabel: {
      formatter: (value) => numberWithCommas(value),
    },
    min: 0,
  },
  series: [
    {
      name: "Revenue",
      data: [],
      type: "bar",
      smooth: true,
      lineStyle: { color: "#D7A81E" },
      barWidth: "10%",
      itemStyle: {
        color: "#000000",
        borderRadius: [50, 50, 0, 0],
      },
      showSymbol: false,
    },
  ],
};

const HighestTripDestinationReport = ({ partnerId, dateRange, chartTypeId }) => {
  const dispatch = useDispatch();
  const [chartOptions, setChartOptions] = useState(options);
  const { highestTripDestinationsReportsReports, loading } = useSelector((state) => state?.reports);

  const processReport = (reports) => {
    if (!Array.isArray(reports)) return [];
    return reports.map((entry) => ({
      name: entry.area.name,
      value: entry.total,
    }));
  };

  useEffect(() => {
    let dd = dateRange;
    if (dateRange?.startDate && dateRange?.endDate) {
      dd = { startDate: dd.startDate.split(" ")[0], endDate: dateRange.endDate.split(" ")[0] };
    }

    const businessId = partnerId === 0 ? "" : partnerId;

    dispatch(FetchHighestTripDestinationsReports({ ...dd, businessId })).catch((err) => {
      notification.error({
        message: "Error",
        description: err?.message ?? "An error has occurred, please try again later",
      });
    });
  }, [dateRange, partnerId]);

  useEffect(() => {
    const data = processReport(highestTripDestinationsReportsReports?.highestAreas ?? []);
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xAxis: { ...prevOptions?.xAxis, data: data.map((item) => item.name) },
      yAxis: { ...prevOptions?.yAxis, data: data.map((item) => item.value) },
      series: prevOptions.series?.map((s) => ({ ...s, data })),
    }));
  }, [highestTripDestinationsReportsReports]);

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      series: chartOptions.series?.map((s) => {
        return { ...s, type: chartTypeId === 1 ? "line" : "bar" };
      }),
    });
  }, [chartTypeId]);

  return (
    <div className="w-full pb-4 pt-8">
      <Loader loading={loading} />
      {!loading ? <>
        <ReactECharts option={chartOptions} />
        <div className="w-full flex justify-center items-center">
          <div className="mt-4 flex items-center gap-x-2">
            <div className="h-2 w-2 bg-black rounded-full"></div>
            <span className="text-labels font-medium">Location</span>
          </div>
        </div>
      </>
        : null
      }
    </div>
  );
};

export default HighestTripDestinationReport;
