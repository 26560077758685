/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { FetchTripSummaryReports } from "../../../redux/actions/reports.action";
import { notification, Pagination } from "antd";
import Loader from "../../../components/Loader";
import ReactECharts from "echarts-for-react";
import { getRandomColor, numberWithCommas } from "../../../utils/constants";

const pageSize = 5;

const initialOptions = {
  tooltip: {
    trigger: "item",
    formatter: (params) => `${params.name}: ₦${numberWithCommas(params.value)} (${params.percent}%)`,
  },
  series: [
    {
      name: "Fare",
      type: "pie",
      radius: ["45%", "60%"],
      data: [],
      label: { show: true, formatter: "{b}: {d}%" },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
      itemStyle: { borderWidth: 3, borderColor: "#ffffff" },
    },
  ],
};

const TripFareReport = ({ partnerId, dateRange }) => {
  const dispatch = useDispatch();
  const [chartOptions, setChartOptions] = useState(initialOptions);
  const [fareList, setFareList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { tripSummaryReports, loading } = useSelector((state) => state?.reports);

  const processReport = (reports) => {
    if (!Array.isArray(reports)) return [];

    const groupedData = {};
    let totalFareSum = 0;

    reports.forEach((entry) => {
      const businessName = entry.business?.name ?? "Unknown";
      const totalFare = parseFloat(entry.totalFare) || 0;
      totalFareSum += totalFare;

      if (!groupedData[businessName]) {
        groupedData[businessName] = { name: businessName, value: 0, color: getRandomColor() };
      }
      groupedData[businessName].value += totalFare;
    });

    const sortedList = Object.values(groupedData).map((item) => ({
      ...item,
      percent: totalFareSum ? ((item.value / totalFareSum) * 100).toFixed(2) : 0,
    }));

    setFareList(sortedList);

    return sortedList.map((item) => ({
      value: item.value,
      name: item.name,
      itemStyle: { color: item.color },
    }));
  };

  useEffect(() => {
    let dd = dateRange;
    if (dateRange?.startDate && dateRange?.endDate) {
      dd = { startDate: dd.startDate.split(" ")[0], endDate: dateRange.endDate.split(" ")[0] };
    }

    const businessId = partnerId === 0 ? "" : partnerId;

    dispatch(FetchTripSummaryReports({ ...dd, businessId })).catch((err) => {
      notification.error({
        message: "Error",
        description: err?.message ?? "An error has occurred, please try again later",
      });
    });
  }, [dateRange, partnerId]);

  useEffect(() => {
    const data = processReport(tripSummaryReports);
    const totalFare = data.reduce((sum, item) => sum + item.value, 0);
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series: prevOptions.series?.map((s) => ({ ...s, data })),
      graphic: [
        {
          type: "text",
          left: "center",
          top: "45%",
          style: {
            text: "Total Fare",
            textAlign: "center",
            fontSize: 12,
            fontWeight: "bold",
            fill: "#737373",
          },
        },
        {
          type: "text",
          left: "center",
          top: "50%",
          style: {
            text: `₦${numberWithCommas(totalFare)}`,
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            fill: "#333",
          },
        },
      ],
    }));
  }, [tripSummaryReports]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPaginatedFareList = () => {
    const start = (currentPage - 1) * pageSize;
    return fareList.slice(start, start + pageSize);
  };

  return (
    <div className="w-full">
      <Loader loading={loading} />
      {!loading ? (
        <div className="w-full grid grid-cols-2">
          <div className="col">
            <ReactECharts option={chartOptions} style={{ height: 500 }} />
          </div>
          <div className="col text-labels">
            <div className="w-full flex flex-col justify-center h-full items-center">
              <div className="w-[65%]">
                <div className="flex justify-between border-b-[1px] border-b-[#737373] px-4">
                  <p className="font-semibold text-[#737373]">Operator</p>
                  <p className="font-semibold text-[#737373]">Fare (₦)</p>
                  <p className="font-semibold text-[#737373]">%</p>
                </div>
                <div>
                  {getPaginatedFareList().map((item, index) => (
                    <div key={index} className="flex justify-between border-b-[1px] border-b-[#E0E0E0] py-2 px-4">
                      <div className="flex items-center gap-x-3">
                        <div className="h-[10px] w-[10px] rounded-full" style={{ backgroundColor: item.color }}></div>
                        <p className="font-normal text-secondary-100">{item.name}</p>
                      </div>
                      <p className="font-normal text-secondary-100">₦{numberWithCommas(item.value)}</p>
                      <p className="font-normal text-secondary-100">{item.percent}%</p>
                    </div>
                  ))}
                </div>
                <Pagination
                  current={currentPage}
                  total={fareList.length}
                  pageSize={pageSize}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  className="mt-2"
                  hideOnSinglePage={true}
                  showLessItems={true}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TripFareReport;
