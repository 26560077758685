import React from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { ReactComponent as DVLADocument } from "../../../assets/icons/dvla-document.svg";
import { ReactComponent as DVLAExtract } from "../../../assets/icons/dvla-extract.svg";
import DVLALicense from "../../../assets/imgs/dvla-license.png";
import Button from "../../../components/Buttons";

const DocumentsModal = ({ handleClose, handleUpdate }) => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  return (
    <div className="w-full">
      <div className="flex justify-between w-full items-center mb-4">
        <div className="flex items-center gap-x-3">
          <DVLADocument />
          <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
            Documents
          </div>
        </div>
        <div className="text-labels text-neutral-200 font-medium">1 / 24</div>
        <div className="flex items-center gap-x-3">
          <div
            className="px-2 h-6 flex items-center justify-center rounded-full dvla-close cursor-pointer text-x-small gap-x-2"
            onClick={handleClose}
          >
            <DVLAExtract />
            Download
          </div>
          <div
            className="p-1 w-6 h-6 flex items-center justify-center rounded-full dvla-close cursor-pointer"
            onClick={handleClose}
          >
            <DVLAClose />
          </div>
        </div>
      </div>
      <div
        className="text-labels border rounded-lg border-[1px] border-gray-300"
      >
        <img src={DVLALicense} alt={"license"} />
      </div>
      {user?.isSiteAdmin &&
        <div className="flex gap-x-4 mt-4">
          <Button
            css={
              "py-[9px] rounded-md lg:text-[11px] text-[10px] xl:px-[10px] px-2"
            }
            state={"PRIMARY"}
            text={"Approve"}
            type={"button"}
            onClick={() => handleUpdate(true)}
          />
          <Button
            css={
              "py-[9px] rounded-md lg:text-[11px] text-[10px] xl:px-[10px] px-2"
            }
            text={"Reject"}
            type={"button"}
            bordered={true}
            onClick={() => handleUpdate(false)}
          />
        </div>}
    </div>
  );
};

export default DocumentsModal;
