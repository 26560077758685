/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { FetchComplianceReports } from "../../../redux/actions/reports.action";
import { notification, Pagination } from "antd";
import Loader from "../../../components/Loader";
import ReactECharts from "echarts-for-react";
import { getRandomColor } from "../../../utils/constants";

const initialOptions = {
  tooltip: {
    trigger: "item",
    formatter: "{b}: {c} ({d}%)",
  },
  series: [
    {
      name: "",
      type: "pie",
      radius: ["45%", "60%"],
      data: [],
      label: {
        show: true,
        formatter: "{b}: {d}%",
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
      itemStyle: {
        borderWidth: 3,
        borderColor: "#ffffff",
      },
    },
  ],
};

const ComplianceReport = ({ partnerId, dateRange }) => {
  const dispatch = useDispatch();
  const [chartOptions, setChartOptions] = useState(initialOptions);
  const [complianceList, setComplianceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const pageSize = 5;
  const { complianceReports, loading } = useSelector((state) => state?.reports);
  const [complianceData, setComplianceData] = useState([]);

  const processReport = (reports) => {
    if (!Array.isArray(reports)) return [];

    let total = 0;
    let totalNonCompliant = 0;
    const compliantOperators = [];
    const nonCompliantOperators = [];
    const compliantColor = getRandomColor();
    const nonCompliantColor = getRandomColor();

    reports.forEach((entry) => {
      total += entry.total ?? 0;
      totalNonCompliant += entry.totalNonCompliant ?? 0;
      const operator = { name: entry?.business?.name };


      if (entry.totalNonCompliant > 0) {
        nonCompliantOperators.push({ ...operator, status: "Non Compliant", color: nonCompliantColor });
      } else {
        compliantOperators.push({ ...operator, status: "Compliant", color: compliantColor });
      }
    });

    const sortedList = [...compliantOperators, ...nonCompliantOperators];
    setComplianceList(sortedList)
    const compliant = total - totalNonCompliant;
    const compliantPercentage = total ? ((compliant / total) * 100).toFixed(2) : 0;
    const nonCompliantPercentage = total ? ((totalNonCompliant / total) * 100).toFixed(2) : 0;

    setComplianceData([
      { name: "Compliant", percentage: compliantPercentage, color: compliantColor },
      { name: "Non Compliant", percentage: nonCompliantPercentage, color: nonCompliantColor },
    ]);

    return [
      { value: compliant, name: "Compliant", itemStyle: { color: compliantColor } },
      { value: totalNonCompliant, name: "Non Compliant", itemStyle: { color: nonCompliantColor } },
    ];
  };

  useEffect(() => {
    let dd = dateRange;
    if (dateRange?.startDate && dateRange?.endDate) {
      dd = { startDate: dd.startDate.split(" ")[0], endDate: dateRange.endDate.split(" ")[0] };
    }

    const businessId = partnerId === 0 ? "" : partnerId;

    dispatch(FetchComplianceReports({ ...dd, businessId })).catch((err) => {
      notification.error({
        message: "Error",
        description: err?.message ?? "An error has occurred, please try again later",
      });
    });
  }, [dateRange, partnerId]);

  useEffect(() => {
    const data = processReport(complianceReports);
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series: prevOptions.series?.map((s) => ({ ...s, data })),
    }));
  }, [complianceReports]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPaginatedComplianceList = () => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return complianceList.slice(start, end);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"< Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next >"}
        </a>
      );
    }
    return originalElement;
  };

  return (
    <div className="w-full">
      <Loader loading={loading} />
      {!loading ?
        <div className="w-full grid grid-cols-2">
          <div className="col">
            <ReactECharts option={chartOptions} style={{ height: 500 }} />
          </div>
          <div className="col text-labels">
            <div className="w-full flex flex-col justify-center h-full items-center">
              <div className="w-[65%]">
                <div className="flex justify-between border-b-[1px] border-b-[#737373] px-4">
                  <p className="font-semibold text-[#737373]">Status</p>
                  <p className="font-semibold text-[#737373]">%</p>
                </div>
                <div>
                  {complianceData.map((item, index) => (
                    <div key={index} className="flex justify-between border-b-[1px] border-b-[#E0E0E0] py-2 px-4">
                      <div className="flex items-center gap-x-3">
                        <div className="h-[10px] w-[10px] rounded-full" style={{ backgroundColor: item.color }}></div>
                        <p className="font-normal text-secondary-100">{item.name}</p>
                      </div>
                      <p className="font-normal text-secondary-100">{item.percentage}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col text-labels">
            <div className="w-full flex flex-col justify-center h-full items-center">
              <div className="w-[65%]">
                <div className="flex justify-between border-b-[1px] border-b-[#737373] px-4">
                  <p className="font-semibold text-[#737373]">Operator</p>
                  <p className="font-semibold text-[#737373]">Status</p>
                </div>
                <div>
                  {getPaginatedComplianceList().map((item, index) => (
                    <div key={index} className="flex justify-between border-b-[1px] border-b-[#E0E0E0] py-2 px-4">
                      <div className="flex items-center gap-x-3">
                        <div className="h-[10px] w-[10px] rounded-full" style={{ backgroundColor: item.color }}></div>
                        <p className="font-normal text-secondary-100">{item.name}</p>
                      </div>
                      <p className="font-normal text-secondary-100">{item.status}</p>
                    </div>
                  ))}
                </div>
                <Pagination
                  current={currentPage}
                  total={complianceList.length}
                  pageSize={pageSize}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  itemRender={itemRender}
                  className="mt-2"
                  showQuickJumper={false}
                  showPrevNextJumpers={false}
                  hideOnSinglePage={true}
                  showLessItems={true}
                />
              </div>
            </div>
          </div> */}
        </div>
        : <></>}
    </div>
  );
};

export default ComplianceReport;
