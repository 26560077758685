import React from "react";
import { Spin } from "antd";
import "./styles.scss";

const Button = (props) => {
  const {
    state,
    text,
    icon,
    extraIcon,
    type,
    bordered,
    css,
    disabled,
    loading,
    ...rest
  } = props;
  return (
    <button
      className={`${
        state === "PRIMARY" ? "button_primary" : "button_light"
      } gap-x-2 lg:gap-x-4 text-labels lg:text-sm-regular xl:px-8 px-3 py-4 ${
        bordered && "btn_bordered"
      } ${css}`}
      type={type ?? "submit"}
      {...rest}
      disabled={disabled || loading}
    >
      {icon && !extraIcon ? (
        <img src={icon} alt={"icon"} className={"h-4"} />
      ) : icon && extraIcon ? (
        <div className={"flex"}>
          <div className={"border-r pr-2"}>
            <img src={icon} alt={"icon"} />
          </div>
          <div className={"pl-2"}>
            <img src={extraIcon} alt={"extraIcon"} />
          </div>
        </div>
      ) : (
        <></>
      )}

      {text ?? "Button"}
      {loading ? (
        <span className={"spinner ml-2"}>
          <Spin size="small" className="btn_loading_spinner" />
        </span>
      ) : (
        ""
      )}
    </button>
  );
};

export default Button;
