import { Types } from "../actions/violations.action";

const INITIAL_STATE = {
  loading: false,
  violations: {},
  export_violations: null,
};

export const violationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_VIOLATIONS_START:
    case Types.EXPORT_VIOLATIONS_START:
      return { ...state, loading: true };
    case Types.FETCH_VIOLATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        violations: action.violations,
      };
    case Types.EXPORT_VIOLATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        export_violations: action.export_violations,
      };
    case Types.FETCH_VIOLATIONS_END:
    case Types.EXPORT_VIOLATIONS_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
