/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles.scss";
import Users from "./Users";
import DVLAInvite from "../../../assets/imgs/dvla-invite.png";
import Button from "../../../components/Buttons";

const Manage = ({
  getUser,
  addUser,
  addPartner,
  handleSearch,
  paginationData,
  setPaginationData,
  setPartnerId,
}) => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  const inviteUser = () => {
    addUser();
  };

  const invitePartner = () => {
    addPartner();
  };
  return (
    <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
      <div className="pb-4 border-b-neutral-100 border-b-[0.5px] w-full flex justify-between">
        <h3 className="text-secondary-300 font-semibold text-[18px]">
          {user?.isSiteAdmin === "ADMIN" ? "Manage Users and Partner Users" : "Manage Users"}
        </h3>
        <div className="flex gap-x-2">
          <Button
            css={
              "py-[9px] rounded-md lg:text-[10px] text-[10px] xl:px-[16px] px-2"
            }
            state={"PRIMARY"}
            text={"Invite User"}
            type={"button"}
            icon={DVLAInvite}
            onClick={inviteUser}
          />
          {user?.isSiteAdmin && <Button
            css={
              "py-[9px] rounded-md lg:text-[10px] text-[10px] xl:px-[16px] px-2"
            }
            state={"PRIMARY"}
            text={"Create Partner User"}
            type={"button"}
            icon={DVLAInvite}
            onClick={invitePartner}
          />}
        </div>
      </div>
      <div className="w-full mt-3">
        <Users
          getUser={getUser}
          handleSearch={handleSearch}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          setPartnerId={setPartnerId}
        />
      </div>
    </div>
  );
};

export default Manage;
