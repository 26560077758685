/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
import { ReactComponent as DVLAFilter } from "../../../assets/icons/dvla-filter.svg";
import "../styles.scss";
import { useSelector } from "react-redux";
import { CHART_TYPES } from "../../../utils/misc/filters";

const ReportsChartsFilters = ({ setPartnerId, setChartTypeId, showType }) => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  const { businesses } = useSelector((state) => state?.businesses);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [open, setOpen] = useState(false);
  const [openChartType, setOpenChartType] = useState(false);
  const [chartType, setChartType] = useState(2);
  const [chartTypeName, setChartTypeName] = useState("");

  const hide = () => {
    setOpen(false);
  };

  const hideChartType = () => {
    setOpenChartType(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleOpenChartTypeChange = (newOpen) => {
    setOpenChartType(newOpen);
  };

  const handleChartType = (id) => {
    setChartType(id);
    setChartTypeId(id);
    hideChartType();
  };
  
  const handleCompnay = (id) => {
    setCompany(id);
    setPartnerId(id)
    hide();
  };

  useEffect(() => {
    if (companies?.length) {
      setCompanyName(companies?.find((c) => c?.id === company)?.name);
    }
  }, [companies, company]);

  useEffect(() => {
    if (CHART_TYPES && CHART_TYPES?.length) {
      setChartTypeName(CHART_TYPES?.find((c) => c?.id === chartType)?.name);
    }
  }, [CHART_TYPES, chartType]);

  useEffect(() => {
    const all = { id: 0, name: "All" };
    let content = [...(businesses?.content ?? [])];
    if (content?.length) {
      content.unshift(all);
      setCompanies(content);
    } else {
      content = [all];
      setCompanies(content);
    }
  }, [businesses]);

  const content = (
    <div className="w-32">
      {companies?.map((c) => (
        <div
          key={c?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleCompnay(c?.id)}
        >
          <Checkbox checked={c?.id === company} className="form-checkbox">
            <div>{c?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  const chartTypeContent = (
    <div className="w-32">
      {CHART_TYPES?.map((c) => (
        <div
          key={c?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleChartType(c?.id)}
        >
          <Checkbox checked={c?.id === chartType} className="form-checkbox">
            <div>{c?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  return (
    <div className="w-full flex justify-end">
      <div className="flex items-center gap-x-3">
        {showType && <Popover
          content={chartTypeContent}
          trigger="click"
          open={openChartType}
          placement="bottomLeft"
          onOpenChange={handleOpenChartTypeChange}
        >
          <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-2 rounded-[4px]">
            <span>{chartTypeName}</span>
            <DVLAChevronDown className="trend-icon" />
          </div>
        </Popover>}
        {user?.isSiteAdmin &&
          <Popover
            content={content}
            trigger="click"
            open={open}
            placement="bottomLeft"
            onOpenChange={handleOpenChange}
          >
            <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px] bg-dark-100">
              <DVLAFilter className="h-4 w-4" />
              <span> Company ({companyName})</span>
              <DVLAChevronDown className="trend-icon" />
            </div>
          </Popover>}
      </div>
    </div>
  );
};

export default ReportsChartsFilters;
