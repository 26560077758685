import { Types } from "../actions/passengers.action";

const INITIAL_STATE = {
  loading: false,
  passengers: {},
  passenger: {},
  exportPassengers: null,
};

export const passengersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_PASSENGERS_START:
    case Types.FETCH_PASSENGER_START:
    case Types.EXPORT_PASSENGERS_START:
      return { ...state, loading: true };
    case Types.FETCH_PASSENGERS_SUCCESS:
      return {
        ...state,
        loading: false,
        passengers: action.passengers,
      };
    case Types.FETCH_PASSENGER_SUCCESS:
      return {
        ...state,
        loading: false,
        passenger: action.passenger,
      };
    case Types.EXPORT_PASSENGERS_SUCCESS:
      return {
        ...state,
        loading: false,
        exportPassengers: action.passenger,
      };
    case Types.FETCH_PASSENGERS_END:
    case Types.FETCH_PASSENGER_END:
    case Types.EXPORT_PASSENGERS_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
