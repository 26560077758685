/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../styles.scss";
import ReportsChartsFilters from "./ReportsChartsFilters";
import ComplianceReport from "./ComplianceReport";
import TripCountReport from "./TripCountReport";
import TripFareReport from "./TripFareReport";
import HighestTripDestinationReport from "./HighestTripDestinationReport";
import HighestTripOriginReport from "./HighestTripOriginReport";

const ReportsCharts = ({ dateRange, reportType }) => {
  const [partnerId, setPartnerId] = useState(0);
  const [chartTypeId, setChartTypeId] = useState(2);

  return (
    <div className="w-full px-10 bg-white mt-6 ride-table-shadow pb-4 pt-8">
      <ReportsChartsFilters setPartnerId={setPartnerId} setChartTypeId={setChartTypeId} showType={reportType === 3 || reportType === 4} />
      <div className="w-full">
        {reportType === 1 ? <TripCountReport partnerId={partnerId} dateRange={dateRange} /> : <></>}
        {reportType === 2 ? <TripFareReport partnerId={partnerId} dateRange={dateRange} /> : <></>}
        {reportType === 3 ? <HighestTripDestinationReport partnerId={partnerId} dateRange={dateRange} chartTypeId={chartTypeId} /> : <></>}
        {reportType === 4 ? <HighestTripOriginReport partnerId={partnerId} dateRange={dateRange} chartTypeId={chartTypeId} /> : <></>}
        {reportType === 5 ? <ComplianceReport partnerId={partnerId} dateRange={dateRange} /> : <></>}
      </div>
    </div>
  );
};

export default ReportsCharts;
