/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../styles.scss";
import { Modal, Popover, Table } from "antd";
import ViolationsFilters from "./ViolationsFilters";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/constants";
import { ReactComponent as DVLAMore } from "../../../assets/icons/dvla-more.svg";
import DVLAView from "../../../assets/icons/dvla-view.svg";
import RideDetails from "../Rides/RideDetails";

const STATUS = "px-2 py-1 bg-[#fcf0ef] text-[#E53838] rounded-md w-fit";

const Violations = ({
  paginationData,
  setPaginationData,
  handleSearch,
}) => {
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const loader = useSelector((state) => state?.violations?.loading);
  const violations = useSelector((state) => state?.violations?.violations);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [ride, setRide] = useState(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "RIDE ID",
      dataIndex: "identifier",
      key: "identifier",
    },
    {
      title: "BUSINESS NAME",
      dataIndex: "businessName",
      key: "businessName",
    },
    {
      title: "DATE ADDED",
      dataIndex: "dateAdded",
      key: "dateAdded",
    },
    {
      title: "FLAGS",
      dataIndex: "nonComplianceFlags",
      key: "nonComplianceFlags",
    },
    {
      title: "M",
      key: "operation",
      render: (record) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <DVLAMore />
          </div>
        </Popover>
      ),
    },
  ];

  const handleMore = () => {
    handleOpenInfoChange(true);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen, record) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    setRide(record.row?.ride)
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const content = (
    <div>
      <div
        className="flex gap-x-4 items-center cursor-pointer text-labels"
        onClick={handleMore}
      >
        <img src={DVLAView} alt={"dvla-view"} className={"h-4"} />
        <div>View ride details</div>
      </div>
    </div>
  );

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  const dataSource =
    violations &&
    violations?.content?.length > 0 &&
    violations?.content?.map((row) => {
      return {
        key: row.id,
        id: row?.id ?? "-",
        identifier: row?.ride?.identifier ?? "-",
        businessName: row?.business?.name ?? "-",
        dateAdded: row?.dateAdded ? formatDate(row?.dateAdded) : "-",
        nonComplianceFlags: row?.nonComplianceFlags && row?.nonComplianceFlags?.length ?
          <div className="flex flex-col gap-y-2">
            {row?.nonComplianceFlags?.map(nc =>
              <span
                key={nc?.flagType}
                className={STATUS}
              >
                {nc?.flagType?.toString()?.toUpperCase() ?? "-"}
              </span>
            )}
          </div> : "-",
        row
      };
    });

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"< Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next >"}
        </a>
      );
    }
    return originalElement;
  };

  const infoContent = (
    <RideDetails handleClose={() => handleOpenInfoChange(false)} ride={ride} />
  );

  return (
    <div className="w-full">
      <ViolationsFilters handleSearch={handleSearch} />
      <div className="w-full mt-10" style={{ minHeight: "20rem" }}>
        <Table
          loading={loader}
          columns={columns}
          dataSource={dataSource}
          size={"small"}
          className={"ps-table"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: violations?.totalElements,
            pageSize: defaultPageSize,
            showTotal: (total) => `Total ${total} items`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender,
          }}
        />
      </div>
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"ride-modal"}
        width={ride?.departureCoordinates?.latitude && ride?.departureCoordinates?.longitude && ride?.arrivalCoordinates?.latitude && ride?.arrivalCoordinates?.longitude ? 800 : 400}
      >
        {infoContent}
      </Modal>
    </div>
  );
};

export default Violations;
