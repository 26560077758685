/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { DisconnectWebSocket, InitializeWebSocket, sendFilterRequest } from "../../../redux/actions/monitor.action";
import Manage from "./Manage";
import Loader from "../../../components/Loader";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";

const monitorTitle = process.env.REACT_APP_MONITOR_TITLE;

const Monitor = () => {
    const dispatch = useDispatch();
    const { reports, loading } = useSelector((state) => state.monitor);
    const [partnerId, setPartnerId] = useState(0);
    const paginationData = { page: 0, size: 100 };

    useEffect(() => {
        dispatch(FetchBusinesses({ ...paginationData }))
    }, [dispatch, paginationData]);

    useEffect(() => {
        dispatch(InitializeWebSocket());
        return () => {
            dispatch(DisconnectWebSocket());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(sendFilterRequest(partnerId));
    }, [partnerId, dispatch]);

    return (
        <MainLayout title={monitorTitle} showToggle={true}>
            <Loader loading={loading} />
            {!loading ? <Manage reports={reports} setPartnerId={setPartnerId} /> : <></>}
        </MainLayout>
    );
};

export default Monitor;
