/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { getRandomColor, numberWithCommas } from "../../../utils/constants";
import ReactECharts from "echarts-for-react";

const initialOptions = {
  tooltip: {
    trigger: "item",
    formatter: (params) => `${params.name}: ₦${numberWithCommas(params.value)} (${params.percent}%)`,
  },
  series: [
    {
      name: "Fare",
      type: "pie",
      radius: ["45%", "60%"],
      data: [],
      label: { show: true, formatter: "{b}: {d}%" },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
      itemStyle: { borderWidth: 3, borderColor: "#ffffff" },
    },
  ],
};

const DoughnutChart = ({ report = {} }) => {
  const [chartOptions, setChartOptions] = useState(initialOptions);

  useEffect(() => {
    const data = report?.value?.map((r) => ({
      value: r.value,
      name: r.key,
      itemStyle: { color: getRandomColor() },
    }));

    const totalFare = data.reduce((sum, item) => sum + item.value, 0);
    const isNumberFormat = report.formatType === "number";
    const currencySymbol = isNumberFormat ? "" : "₦";

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      tooltip: {
        trigger: "item",
        formatter: (params) =>
          `${params.name}: ${currencySymbol}${numberWithCommas(params.value)} (${params.percent}%)`,
      },
      series: prevOptions.series?.map((s) => ({ ...s, data })),
      graphic: [
        {
          type: "text",
          left: "center",
          top: "45%",
          style: {
            text: "Total",
            textAlign: "center",
            fontSize: 12,
            fontWeight: "bold",
            fill: "#737373",
          },
        },
        {
          type: "text",
          left: "center",
          top: "50%",
          style: {
            text: `${currencySymbol}${numberWithCommas(totalFare)}`,
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            fill: "#333",
          },
        },
      ],
    }));
  }, [report]);

  return (
    <div className="w-full monitor-card bg-white p-5 flex flex-col gap-y-2">
      <p className="text-sm-headline font-bold mb-2 text-left">{report?.title}</p>
      <ReactECharts option={chartOptions} style={{ height: 300 }} />
    </div>
  );
};

export default DoughnutChart;
