import {
  getComplianceReports,
  getHighestTripDestinationsReports,
  getHighestTripOriginsReports,
  getTripSummaryReports,
} from "../_api/index";

export const Types = {
  FETCH_COMPLIANCE_REPORTS_START: "FETCH_COMPLIANCE_REPORTS_START",
  FETCH_COMPLIANCE_REPORTS_END: "FETCH_COMPLIANCE_REPORTS_END",
  FETCH_COMPLIANCE_REPORTS_SUCCESS: "FETCH_COMPLIANCE_REPORTS_SUCCESS",

  FETCH_TRIP_SUMMARY_REPORTS_START: "FETCH_TRIP_SUMMARY_REPORTS_START",
  FETCH_TRIP_SUMMARY_REPORTS_END: "FETCH_TRIP_SUMMARY_REPORTS_END",
  FETCH_TRIP_SUMMARY_REPORTS_SUCCESS: "FETCH_TRIP_SUMMARY_REPORTS_SUCCESS",

  FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_START:
    "FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_START",
  FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_END:
    "FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_END",
  FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_SUCCESS:
    "FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_SUCCESS",

  FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_START:
    "FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_START",
  FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_END:
    "FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_END",
  FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_SUCCESS:
    "FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_SUCCESS",
};

export const FetchComplianceReports = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_COMPLIANCE_REPORTS_START });
  return new Promise((resolve, reject) => {
    getComplianceReports(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_COMPLIANCE_REPORTS_SUCCESS,
          complianceReports: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_COMPLIANCE_REPORTS_END });
        reject(err);
      });
  });
};

export const FetchTripSummaryReports = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_TRIP_SUMMARY_REPORTS_START });
  return new Promise((resolve, reject) => {
    getTripSummaryReports(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_TRIP_SUMMARY_REPORTS_SUCCESS,
          tripSummaryReports: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_TRIP_SUMMARY_REPORTS_END });
        reject(err);
      });
  });
};

export const FetchHighestTripDestinationsReports = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_START });
  return new Promise((resolve, reject) => {
    getHighestTripDestinationsReports(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_SUCCESS,
          highestTripDestinationsReportsReports: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_END });
        reject(err);
      });
  });
};

export const FetchHighestTripOriginsReports = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_START });
  return new Promise((resolve, reject) => {
    getHighestTripOriginsReports(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_SUCCESS,
          highestTripOriginsReportsReports: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_END });
        reject(err);
      });
  });
};
