import {
  exportDrivers,
  getDriver,
  getDrivers,
  updateDriver,
  uploadDocument,
} from "../_api/index";

export const Types = {
  ALL_DRIVERS_START: "ALL_DRIVERS_START",
  ALL_DRIVERS_SUCCESS: "ALL_DRIVERS_SUCCESS",
  ALL_DRIVERS_END: "ALL_DRIVERS_END",

  DRIVER_START: "DRIVER_START",
  DRIVER_SUCCESS: "DRIVER_SUCCESS",
  DRIVER_END: "DRIVER_END",

  UPDATE_DRIVER_START: "UPDATE_DRIVER_START",
  UPDATE_DRIVER_SUCCESS: "UPDATE_DRIVER_SUCCESS",
  UPDATE_DRIVER_END: "UPDATE_DRIVER_END",

  UPLOAD_DRIVER_DOCUMENT_START: "UPLOAD_DRIVER_DOCUMENT_START",
  UPLOAD_DRIVER_DOCUMENT_SUCCESS: "UPLOAD_DRIVER_DOCUMENT_SUCCESS",
  UPLOAD_DRIVER_DOCUMENT_END: "UPLOAD_DRIVER_DOCUMENT_END",

  EXPORT_DRIVERS_START: "EXPORT_DRIVERS_START",
  EXPORT_DRIVERS_SUCCESS: "EXPORT_DRIVERS_SUCCESS",
  EXPORT_DRIVERS_END: "EXPORT_DRIVERS_END",
};

export const FetchDrivers = (param) => (dispatch) => {
  dispatch({ type: Types.ALL_DRIVERS_START });
  return new Promise((resolve, reject) => {
    getDrivers(param)
      .then((res) => {
        dispatch({
          type: Types.ALL_DRIVERS_SUCCESS,
          all_drivers: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ALL_DRIVERS_END });
        reject(err);
      });
  });
};

export const FetchDriver = (id) => (dispatch) => {
  dispatch({ type: Types.DRIVER_START });
  return new Promise((resolve, reject) => {
    getDriver(id)
      .then((res) => {
        dispatch({
          type: Types.DRIVER_SUCCESS,
          driver: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.DRIVER_END });
        reject(err);
      });
  });
};

export const UpdateDriverProfile = (payload) => (dispatch) => {
  dispatch({ type: Types.UPDATE_DRIVER_START });
  return new Promise((resolve, reject) => {
    updateDriver(payload)
      .then((res) => {
        dispatch({
          type: Types.UPDATE_DRIVER_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.UPDATE_DRIVER_END });
        reject(err);
      });
  });
};

export const UploadDriverDocument = (formData, config, id) => (dispatch) => {
  dispatch({ type: Types.UPLOAD_DRIVER_DOCUMENT_START });
  return new Promise((resolve, reject) => {
    uploadDocument(formData, config, id)
      .then((res) => {
        dispatch({
          type: Types.UPLOAD_DRIVER_DOCUMENT_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.UPLOAD_DRIVER_DOCUMENT_END });
        reject(err);
      });
  });
};

export const ExportDrivers = (param) => (dispatch) => {
  dispatch({ type: Types.EXPORT_DRIVERS_START });
  return new Promise((resolve, reject) => {
    exportDrivers(param)
      .then((res) => {
        dispatch({
          type: Types.EXPORT_DRIVERS_SUCCESS,
          export_drivers: res?.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.EXPORT_DRIVERS_END });
        reject(err);
      });
  });
};
