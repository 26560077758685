import { combineReducers } from "redux";

import { authReducer } from "./auths.reducer";
import { businessesReducer } from "./businesses.reducer";
import { ridesReducer } from "./rides.reducer";
import { vehicleReducer } from "./vehicles.reducer";
import { usersReducer } from "./users.reducer";
import { invoicesReducer } from "./invoices.reducer";
import { statsReducer } from "./stats.reducer";
import { driversReducer } from "./drivers.reducer";
import { flagsReducer } from "./flags.reducer";
import { passengersReducer } from "./passengers.reducer";
import { reportsReducer } from "./reports.reducer";
import { violationsReducer } from "./violations.reducer";
import { monitorReducer } from "./monitor.reducer";

const rootReducer = combineReducers({
  auths: authReducer,
  businesses: businessesReducer,
  rides: ridesReducer,
  vehicles: vehicleReducer,
  users: usersReducer,
  invoices: invoicesReducer,
  stats: statsReducer,
  drivers: driversReducer,
  flags: flagsReducer,
  passengers: passengersReducer,
  reports: reportsReducer,
  violations: violationsReducer,
  monitor: monitorReducer,
});

export default rootReducer;
