import React, { useEffect, useState } from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { formatDate, GOOGLE_MAP_API_KEY, numberWithCommas } from "../../../utils/constants";
import GoogleMapReact from 'google-map-react';
import DVLAMapMarker from "../../../assets/imgs/dvla-map-marker.png";
import { useDispatch } from "react-redux";
import { FetchPassenger } from "../../../redux/actions/passengers.action";
import { Modal, notification } from "antd";
import PassengerDetails from "../Passengers/PassengerDetails";

const RideDetails = ({ handleClose, ride }) => {
  const dispatch = useDispatch();
  const defaultProps = {
    center: {
      lat: +ride?.departureCoordinates?.latitude || 10.99835602,
      lng: +ride?.departureCoordinates?.longitude || 77.01502627,
    },
    zoom: 12,
  };

  const [openInfo, setOpenInfo] = useState(false);
  const [passenger, setPassenger] = useState({});
  const [departureAddress, setDepartureAddress] = useState("");
  const [arrivalAddress, setArrivalAddress] = useState("");

  useEffect(() => {
    if (ride?.departureCoordinates) {
      getAddress(ride.departureCoordinates.latitude, ride.departureCoordinates.longitude, setDepartureAddress);
    }
    if (ride?.arrivalCoordinates) {
      getAddress(ride.arrivalCoordinates.latitude, ride.arrivalCoordinates.longitude, setArrivalAddress);
    }
  }, [ride]);

  const getAddress = async (lat, lng, setAddress) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_API_KEY}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      } else {
        setAddress("Address not found");
      }
    } catch (error) {
      setAddress("Error retrieving address");
    }
  };

  const viewPassenger = (passengerId) => {
    if (!passengerId) return;
    dispatch(FetchPassenger(passengerId)).then(res => {
      setPassenger(res?.data);
      handleOpenInfoChange(true);
    }).catch(err => {
      notification.error({
        message: "Error",
        description:
          err?.message ?? "An error has ocurred, please try again later",
      });
    });
  }

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const infoContent = (
    <PassengerDetails handleClose={() => handleOpenInfoChange(false)} passenger={passenger} />
  );

  return (
    <div className="w-full">
      <div className="flex justify-end">
        <div
          className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-4">
        {/* Ride Details */}
        <div className="col">
          <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
            Ride Details
          </div>
          <div className="text-labels rounded-lg border-[1px] border-gray-300">
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Company</div>
              <div className="text-secondary-100 font-medium">{ride?.business?.name}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Driver’s name</div>
              <div className="text-secondary-100 font-medium">{ride?.driver?.firstName ?? "---"} {ride?.driver?.lastName ?? "---"}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">License No.</div>
              <div className="text-secondary-100 font-medium">{ride?.business?.licenseNumber}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Vehicle Reg.</div>
              <div className="text-secondary-100 font-medium">{ride?.vehicle?.licensePlateNumber ?? "---"}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Start point</div>
              <div className="text-secondary-100 font-medium">{ride?.departureCoordinates?.longitude}, {ride?.departureCoordinates?.latitude}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">End point</div>
              <div className="text-secondary-100 font-medium">{ride?.arrivalCoordinates?.longitude}, {ride?.arrivalCoordinates?.latitude}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Trip Start Time</div>
              <div className="text-secondary-100 font-medium">{formatDate(ride?.rideStartTimestamp)}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Trip End Time</div>
              <div className="text-secondary-100 font-medium">{formatDate(ride?.rideEndTimestamp)}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Passenger’s name</div>
              <div className="text-secondary-100 font-medium">{ride?.passgName ?? "---"}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Passenger’s number</div>
              <div className="text-secondary-100 font-medium">{ride?.passPhoneNo ?? "---"}</div>
            </div>
            <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
              <div className="text-neutral-200">Ride fare (NGN)</div>
              <div className="text-secondary-100 font-medium">{ride?.rideFare ? numberWithCommas(ride?.rideFare) : ride?.fare ? numberWithCommas(ride?.fare) : "---"}</div>
            </div>
            <div className="flex justify-between px-3 py-4">
              <div className="text-neutral-200">Passenger details</div>
              <div onClick={() => viewPassenger(ride?.passengerId)} className={`font-medium ${ride?.passengerId ? 'cursor-pointer hover:underline text-[#23BE65]' : 'text-secondary-100'}`}>{ride?.passengerId ? 'View' : '---'}</div>
            </div>
          </div>
        </div>

        {/* Ride Route */}
        <div className="col">
          <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
            Ride Route
          </div>
          <div className="text-labels rounded-lg border-[1px] border-gray-300">
            <div style={{ height: '450px', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                {ride?.departureCoordinates && (
                  <MapMarker
                    lat={+ride.departureCoordinates.latitude}
                    lng={+ride.departureCoordinates.longitude}
                    label={`${ride?.fromArea?.name}, ${ride?.fromArea?.state}`}
                  />
                )}
                {ride?.arrivalCoordinates && (
                  <MapMarker
                    lat={+ride.arrivalCoordinates.latitude}
                    lng={+ride.arrivalCoordinates.longitude}
                    label={`${ride?.toArea?.name}, ${ride?.toArea?.state}`}
                  />
                )}
              </GoogleMapReact>
            </div>
          </div>
          <div className="text-labels mt-4 py-4">
            <div className="text-neutral-200">Departure Address</div>
            <div className="text-secondary-100 font-medium text-labels">{departureAddress || "Fetching..."}</div>
            <div className="text-neutral-200 mt-4">Arrival Address</div>
            <div className="text-secondary-100 font-medium text-labels">{arrivalAddress || "Fetching..."}</div>
          </div>
        </div>
      </div>

      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"passenger-modal"}
        width={600}
      >
        {infoContent}
      </Modal>
    </div>
  );
};

const MapMarker = ({ label }) => {
  return (
    <div style={{ position: "absolute", transform: "translate(-50%, -100%)" }}>
      <img
        alt="marker"
        src={DVLAMapMarker}
        style={{ width: "30px", height: "30px" }}
      />
      <div style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold", marginTop: "5px" }}>
        {label}
      </div>
    </div>
  );
};

export default RideDetails;
