/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../styles.scss";
import { Modal, Popover, Table } from "antd";
import PassengersFilters from "./PassengersFilters";
import { useSelector } from "react-redux";
import { dateFormat } from "../../../utils/constants";
import { ReactComponent as DVLAMore } from "../../../assets/icons/dvla-more.svg";
import DVLAView from "../../../assets/icons/dvla-view.svg";
import PassengerDetails from "./PassengerDetails";

const Passengers = ({
  paginationData,
  setPaginationData,
  handleSearch,
}) => {
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const loader = useSelector((state) => state?.passengers?.loading);
  const passengers = useSelector((state) => state?.passengers?.passengers);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [passenger, setPassenger] = useState(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "DATE OF BIRTH",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "M",
      key: "operation",
      render: (record) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <DVLAMore />
          </div>
        </Popover>
      ),
    },
  ];

  const handleMore = () => {
    handleOpenInfoChange(true);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen, record) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    setPassenger(record.row)
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const content = (
    <div>
      <div
        className="flex gap-x-4 items-center cursor-pointer text-labels"
        onClick={handleMore}
      >
        <img src={DVLAView} alt={"dvla-view"} className={"h-4"} />
        <div>View passenger details</div>
      </div>
    </div>
  );

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  const dataSource =
    passengers &&
    passengers?.content?.length > 0 &&
    passengers?.content?.map((row) => {
      return {
        key: row.id,
        id: row?.id ?? "-",
        firstName: row?.firstName ?? "-",
        lastName: row?.lastName ?? "-",
        email: row?.email ?? "-",
        phoneNumber: row?.phoneNumber ?? "-",
        dateOfBirth: row?.dateOfBirth ? dateFormat(row?.dateOfBirth) : "-",
        row
      };
    });

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"< Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next >"}
        </a>
      );
    }
    return originalElement;
  };

  const infoContent = (
    <PassengerDetails handleClose={() => handleOpenInfoChange(false)} passenger={passenger} />
  );

  return (
    <div className="w-full">
      <PassengersFilters handleSearch={handleSearch} />
      <div className="w-full mt-10" style={{ minHeight: "20rem" }}>
        <Table
          loading={loader}
          columns={columns}
          dataSource={dataSource}
          size={"small"}
          className={"ps-table"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: passengers?.totalElements,
            pageSize: defaultPageSize,
            showTotal: (total) => `Total ${total} items`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender,
          }}
        />
      </div>
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"passenger-modal"}
        width={600}
      >
        {infoContent}
      </Modal>
    </div>
  );
};

export default Passengers;
