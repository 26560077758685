/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input } from "antd";
import React, { useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { ReactComponent as DVLAClose } from "../../assets/icons/dvla-close.svg";
import FieldContainer from "../Inputs/FIeldContainer";
import FloatLabel from "../Inputs/FloatLabel";
import Button from "../Buttons";

const EmailPrompt = ({ open, setOpen, callBack }) => {
    const [form] = Form.useForm();

    const initialValues = { email: "" };

    useEffect(() => {
        if (open) {
            form.resetFields();
        }
    }, [open]);

    const handleSubmit = (values, { resetForm }) => {
        callBack(values.email);
        resetForm();
        form.resetFields();
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            closable={false}
            footer={null}
            onCancel={() => setOpen(false)}
            className="email-modal"
            width={500}
        >
            <div className="w-full">
                <div className="flex justify-end">
                    <div
                        className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
                        onClick={() => setOpen(false)}
                    >
                        <DVLAClose />
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
                        Enter email address
                    </div>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                        validationSchema={validator}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form form={form} onFinish={handleSubmit}>
                                <div className="mb-4">
                                    <FieldContainer iconPlacementCss="iconCss">
                                        <FloatLabel label="Email" value={values?.email || ""}>
                                            <Form.Item
                                                name="email"
                                                help={touched.email && errors.email ? errors.email : ""}
                                                validateStatus={touched.email && errors.email ? "error" : "success"}
                                            >
                                                <Input
                                                    type="email"
                                                    className="form-field_input_2"
                                                    value={values.email}
                                                    onChange={handleChange("email")}
                                                    onBlur={handleBlur("email")}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </FieldContainer>
                                </div>
                                <div className="flex justify-center">
                                    <Button
                                        css="w-full"
                                        state="PRIMARY"
                                        text="Submit"
                                        type="submit"
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    );
};

const validator = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
});

export default EmailPrompt;
