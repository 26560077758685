import { Types } from "../actions/monitor.action";

const INITIAL_STATE = {
  loading: false,
  reports: [],
};

export const monitorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.MONITOR_REPORTS_START:
      return { ...state, loading: true };
    case Types.MONITOR_REPORTS_SUCCESS:
      return { ...state, loading: false, reports: action.reports };
    case Types.MONITOR_REPORTS_END:
      return { ...state, loading: false };
    default:
      return state;
  }
};
