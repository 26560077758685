import React, { useState } from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { dateFormat } from "../../../utils/constants";
import DummyUser from "../../../assets/imgs/dummy-user.jpg";

const PassengerDetails = ({ handleClose, passenger }) => {
  const [imgSrc, setImgSrc] = useState(passenger?.photoUrl || DummyUser);

  return (
    <div className="w-full">
      <div className="flex justify-end">
        <div
          className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>

      {/* Passenger Details */}
      <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
        Passenger Details
      </div>
      <div className="w-full flex justify-end mb-6">
        <div className="h-[200px] w-[200px] bg-neutral-200 rounded-[4px]">
          <img
            src={imgSrc}
            alt="Loaded content"
            className="w-full h-full object-cover"
            onError={() => setImgSrc(DummyUser)}
          />
        </div>
      </div>
      <div className="text-labels rounded-lg border-[1px] border-gray-300">
        <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Name</div>
          <div className="text-secondary-100 font-medium">{passenger?.firstName ?? '---'} {passenger?.lastName ?? '---'}</div>
        </div>
        <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Email</div>
          <div className="text-secondary-100 font-medium">{passenger?.email ?? '---'}</div>
        </div>
        <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Phone Number</div>
          <div className="text-secondary-100 font-medium">{passenger?.phoneNumber ?? "---"}</div>
        </div>
        <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Gender</div>
          <div className="text-secondary-100 font-medium">{passenger?.gender ?? "---"}</div>
        </div>
        <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Marital Status</div>
          <div className="text-secondary-100 font-medium">{passenger?.maritalStatus ?? "---"}</div>
        </div>
        <div className="flex justify-between px-3 border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Date of Birth</div>
          <div className="text-secondary-100 font-medium">{passenger?.dateOfBirth ? dateFormat(passenger?.dateOfBirth) : "---"}</div>
        </div>
        <div className="flex justify-between px-3 py-4">
          <div className="text-neutral-200">Address</div>
          <div className="text-secondary-100 font-medium">{passenger?.residentialAddress ?? "---"}</div>
        </div>
      </div>

      {/* Trip History */}
      <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
        Trip History
      </div>
      <div className="text-labels rounded-lg border-[1px] border-gray-300">

      </div>
    </div>
  );
};

export default PassengerDetails;
