import React, { useEffect, useState } from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import Button from "../../../components/Buttons";
import { Field, Formik } from "formik";
import { DatePicker, Form, Input, Select, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { localISOTime } from "../../../utils/constants";
import { CAR_BRAND_MODEL, CAR_TYPES, COLORS } from "../../../utils/misc/database";
import { UpdateVehicleProfile } from "../../../redux/actions/vehicles.action";
import dayjs from "dayjs";

const brands = CAR_BRAND_MODEL.map(crm => {
  return { value: crm.brand, label: crm.brand }
})

const YEARS = [];
const d = new Date();
let year = d.getFullYear() + 1;

for (let i = 0; i < 200; i++) {
  if (1970 + i === year) {
    break;
  }
  const data = { value: (1970 + i).toString(), label: (1970 + i).toString() }
  YEARS.push(data);
}

const UpdateVehicle = ({ handleClose, handleUpdate, vehicle, id }) => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.vehicles.loading);
  const [models, setModels] = useState([]);
  const [values, setValues] = useState({
    type: "",
    brand: "",
    model: "",
    vin: "",
    vehicleYear: "",
    color: "",
    licensePlateNumber: "",
    registrationNumber: "",
    verificationStatus: "",
    registrationDate: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (vehicle) {
      setValues({
        type: vehicle?.type ?? "",
        brand: vehicle?.brand ?? "",
        model: vehicle?.model ?? "",
        vin: vehicle?.vin ?? "",
        vehicleYear: vehicle?.vehicleYear ?? "",
        color: vehicle?.color ?? "",
        licensePlateNumber: vehicle?.licensePlateNumber ?? "",
        registrationNumber: vehicle?.registrationNumber ?? "",
        verificationStatus: vehicle?.verificationStatus ?? "",
        registrationDate: vehicle?.registrationDate ? dayjs(new Date(vehicle?.registrationDate)) : "",
      })
    }
  }, [vehicle])

  const handleModels = (brand) => {
    const mm = CAR_BRAND_MODEL.find(cbm => cbm.brand === brand).models.map(mdls => {
      return { value: mdls, label: mdls }
    })
    setModels(mm);
  }

  const updateVehicle = (values, { resetForm }) => {
    const data = {
      ...values,
      registrationDate: localISOTime(new Date(values?.registrationDate)),
      id
    }
    dispatch(UpdateVehicleProfile(data))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description: "Vehicle Profile Updated Successfully!",
        });
        resetForm();
        form.resetFields();
        handleUpdate();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  return (
    <div className="w-full">
      <div className="flex justify-end">
        <div
          className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>
      <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
        Update Vehicle Profile
      </div>
      <div className="text-labels mt-2">
        <Formik
          className="w-full"
          initialValues={values}
          onSubmit={updateVehicle}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Type"
                    value={values?.type || ""}
                  >
                    <Form.Item
                      name={"type"}
                      help={
                        touched.type && errors.type
                          ? errors.type
                          : ""
                      }
                      validateStatus={
                        touched.type && errors.type
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.type || ""}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("type", e);
                            }}
                            options={CAR_TYPES}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Brand"
                    value={values?.brand || ""}
                  >
                    <Form.Item
                      name={"brand"}
                      help={
                        touched.brand && errors.brand
                          ? errors.brand
                          : ""
                      }
                      validateStatus={
                        touched.brand && errors.brand
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.brand || ""}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("brand", e);
                              setFieldValue("model", "")
                              handleModels(e);
                            }}
                            options={brands}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Model"
                    value={values?.model || ""}
                  >
                    <Form.Item
                      name={"model"}
                      help={
                        touched.model && errors.model
                          ? errors.model
                          : ""
                      }
                      validateStatus={
                        touched.model && errors.model
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.model || ""}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("model", e);
                            }}
                            options={models}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Vin"
                    value={values?.vin || ""}
                  >
                    <Form.Item
                      name={"vin"}
                      help={
                        touched.vin && errors.vin
                          ? errors.vin
                          : ""
                      }
                      validateStatus={
                        touched.vin && errors.vin
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"text"}
                            className={"form-field_input_2"}
                            value={values?.vin || ""}
                            onChange={(e) => {
                              setFieldValue("vin", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("vin", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Vehicle Year"
                    value={values?.vehicleYear || ""}
                  >
                    <Form.Item
                      name={"vehicleYear"}
                      help={
                        touched.vehicleYear && errors.vehicleYear
                          ? errors.vehicleYear
                          : ""
                      }
                      validateStatus={
                        touched.vehicleYear && errors.vehicleYear
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.vehicleYear || ""}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("vehicleYear", e);
                            }}
                            options={YEARS}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Color"
                    value={values?.color || ""}
                  >
                    <Form.Item
                      name={"color"}
                      help={
                        touched.color && errors.color
                          ? errors.color
                          : ""
                      }
                      validateStatus={
                        touched.color && errors.color
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.color || ""}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("color", e);
                            }}
                            options={COLORS}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="License Plate Number"
                    value={values?.licensePlateNumber || ""}
                  >
                    <Form.Item
                      name={"licensePlateNumber"}
                      help={
                        touched.licensePlateNumber && errors.licensePlateNumber
                          ? errors.licensePlateNumber
                          : ""
                      }
                      validateStatus={
                        touched.licensePlateNumber && errors.licensePlateNumber
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"text"}
                            className={"form-field_input_2"}
                            value={values?.licensePlateNumber || ""}
                            onChange={(e) => {
                              setFieldValue("licensePlateNumber", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("licensePlateNumber", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Registration Number"
                    value={values?.registrationNumber || ""}
                  >
                    <Form.Item
                      name={"registrationNumber"}
                      help={
                        touched.registrationNumber && errors.registrationNumber
                          ? errors.registrationNumber
                          : ""
                      }
                      validateStatus={
                        touched.registrationNumber && errors.registrationNumber
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"text"}
                            className={"form-field_input_2"}
                            value={values?.registrationNumber || ""}
                            onChange={(e) => {
                              setFieldValue("registrationNumber", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("registrationNumber", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Verification Status"
                    value={values?.verificationStatus || ""}
                  >
                    <Form.Item
                      name={"verificationStatus"}
                      help={
                        touched.verificationStatus && errors.verificationStatus
                          ? errors.verificationStatus
                          : ""
                      }
                      validateStatus={
                        touched.verificationStatus && errors.verificationStatus
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.verificationStatus || ""}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("verificationStatus", e);
                            }}
                            options={[
                              { value: "PENDING", label: "Pending" },
                              { value: "VERIFIED", label: "Verified" },
                              { value: "FAILED", label: "Failed" },
                            ]}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Registration Date"
                    value={values?.registrationDate || ""}
                  >
                    <Form.Item
                      name={"registrationDate"}
                      help={
                        touched.registrationDate && errors.registrationDate
                          ? errors.registrationDate
                          : ""
                      }
                      validateStatus={
                        touched.registrationDate && errors.registrationDate
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <DatePicker
                            showTime
                            className={"form-field_input_2"}
                            placeholder={""}
                            value={values?.registrationDate || ""}
                            onChange={(e) => {
                              setFieldValue("registrationDate", e);
                            }}
                            onOk={(e) => {
                              setFieldValue("registrationDate", e)
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={"PRIMARY"}
                  text={"Update Driver"}
                  type={"submit"}
                  loading={loader}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  type: yup
    .string()
    .required("Type is required"),
  brand: yup
    .string()
    .required("Brand is required"),
  model: yup
    .string()
    .required("Model is required"),
  vin: yup
    .string()
    .min(3, "Vin should be minimum of 3 characters")
    .required("Vin is required"),
  vehicleYear: yup
    .string()
    .required("Vehicle year is required"),
  color: yup
    .string()
    .required("Color is required"),
  licensePlateNumber: yup
    .string()
    .min(3, "License plate number should be minimum of 3 characters")
    .required("License plate number is required"),
  registrationNumber: yup
    .string()
    .min(3, "Registration number should be minimum of 10 characters")
    .required("Registration number is required"),
  verificationStatus: yup
    .string()
    .required("Verification status is required"),
  registrationDate: yup
    .string()
    .required("Registration date is required"),
});

export default UpdateVehicle;
