/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import { useDispatch } from "react-redux";
import Manage from "./Manage";
import { debounce } from "lodash";
import { ExportPassengers, FetchPassengers } from "../../../redux/actions/passengers.action";
import { download } from "../../../utils/constants";

const Passengers = () => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState({ query: "" });
    const [paginationData, setPaginationData] = useState({
        page: 0,
        size: 10,
    });

    useEffect(() => {
        dispatch(FetchPassengers({ ...paginationData, ...query }));
    }, [dispatch, paginationData, query]);

    const handleSearch = (value) => {
        performSearch(value);
    };

    const performSearch = debounce((value) => {
        if (value.length > 0) {
            setQuery({ query: value });
        } else {
            setQuery({ query: "" });
        }
    }, 700);

    const exportData = () => {
        dispatch(ExportPassengers(paginationData)).then(async (res) => {
            download("data:text/csv;charset=utf-8," + res?.data, "passengers.csv")
        })
    }

    return (
        <MainLayout title={"Passengers"}>
            <Manage
                exportData={exportData}
                handleSearch={handleSearch}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
            />
        </MainLayout>
    );
};

export default Passengers;
