/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles.scss";
import Passengers from "./Passengers";
import DVLAExport from "../../../assets/icons/dvla-export.svg";
import Button from "../../../components/Buttons";

const Manage = ({
  exportData,
  handleSearch,
  paginationData,
  setPaginationData,
}) => {
  const handleExport = () => {
    exportData();
  };
  return (
    <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
      <div className="pb-4 border-b-neutral-100 border-b-[0.5px] w-full flex justify-between">
        <h3 className="text-secondary-300 font-semibold text-[18px]">
          Manage Passengers
        </h3>
        <div className="flex gap-x-2">
          <Button
            css={
              "py-[9px] rounded-md lg:text-[10px] text-[10px] xl:px-[16px] px-2"
            }
            state={"PRIMARY"}
            text={"Export Data"}
            type={"button"}
            icon={DVLAExport}
            onClick={handleExport}
          />
        </div>
      </div>
      <div className="w-full mt-3">
        <Passengers
          handleSearch={handleSearch}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
        />
      </div>
    </div>
  );
};

export default Manage;
