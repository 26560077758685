import React from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import Button from "../../../components/Buttons";
import { formatDate, numberWithCommas } from "../../../utils/constants";

const InvoiceDetails = ({ handleClose, handleUpdate, invoice }) => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  return (
    <div className="w-full">
      <div className="flex justify-end">
        <div
          className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>
      <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
        Invoice Details
      </div>
      <div className="text-labels border rounded-lg border-[1px] border-gray-300">
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Invoice name</div>
          <div className="text-secondary-100 font-medium">{invoice?.name}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Company</div>
          <div className="text-secondary-100 font-medium">{invoice?.business?.name}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Date sent</div>
          <div className="text-secondary-100 font-medium">{formatDate(invoice?.createDate)}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Status</div>
          <div className="text-secondary-100 font-medium">{invoice?.status}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Due date</div>
          <div className="text-secondary-100 font-medium">{formatDate(invoice?.dueByDate)}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Amount</div>
          <div className="text-secondary-100 font-medium">₦{numberWithCommas(invoice?.amount)}</div>
        </div>
      </div>
      {user?.isSiteAdmin && <div className="flex gap-x-4 mt-4">
        {invoice?.status !== "PAID" && <Button
          css={
            "py-[9px] rounded-md btn-style paid"
          }
          text={"Mark as paid"}
          type={"button"}
          bordered={true}
          onClick={() => handleUpdate(invoice?.id, "PAID")}
        />}
        {invoice?.status !== "PENDING" && <Button
          css={
            "py-[9px] rounded-md btn-style pending"
          }
          text={"Mark as pending"}
          type={"button"}
          bordered={true}
          onClick={() => handleUpdate(invoice?.id, "PENDING")}
        />}
        {invoice?.status !== "OVERDUE" && <Button
          css={
            "py-[9px] rounded-md btn-style overdue"
          }
          text={"Mark as overdue"}
          type={"button"}
          bordered={true}
          onClick={() => handleUpdate(invoice?.id, "OVERDUE")}
        />}
      </div>}
    </div>
  );
};

export default InvoiceDetails;
