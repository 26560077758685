/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Modal, Popover } from "antd";
import React, { useState } from "react";
import "../styles.scss";
import DateFilter from "../../../components/DateFilter";
import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
import { ReactComponent as DVLACalendar } from "../../../assets/icons/dvla-calendar.svg";
import { ReactComponent as DVLAFilter } from "../../../assets/icons/dvla-filter.svg";

const ReportsFilters = ({ setDateRange, getReportType }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [activeDateFilter, setActiveDateFilter] = useState(1);
  const [activeDateFilterName, setActiveDateFilterName] = useState("All Time");
  const [reportType, setReportType] = useState(1);
  const [reportName, setReportName] = useState("Trip Count Per Operator");
  const reportTypes = [
    { id: 1, name: "Trip Count Per Operator" },
    { id: 2, name: "Trip Fare Per Operator" },
    { id: 3, name: "Top 10 Destinations" },
    { id: 4, name: "Top 10 Origin" },
    { id: 5, name: "Non Compliance Report" }
  ];
  const [open, setOpen] = useState(false);

  const hideFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenFilterChange = (newOpen) => {
    setOpenFilter(newOpen);
  };

  const handleFilter = (id) => {
    setActiveDateFilter(id);
    hideFilter();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleReport = (id) => {
    setReportType(id);
    setReportName(reportTypes.find(rt => rt.id === id).name);
    getReportType(id);
    hide();
  };

  const filterContent = (
    <DateFilter
      activeDateFilter={activeDateFilter}
      setActiveDateFilter={handleFilter}
      setActiveDateFilterName={setActiveDateFilterName}
      setDateRange={setDateRange}
    />
  );

  const content = (
    <div className="w-32">
      {reportTypes?.map((rt) => (
        <div
          key={rt?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleReport(rt?.id)}
        >
          <Checkbox checked={rt?.id === reportType} className="form-checkbox">
            <div>{rt?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  return (
    <div className="w-full bg-primary-400 rounded-lg text-sm-regular p-6 text-white flex justify-between items-center">
      <p className="font-semibold text-sm-headline">Generate Report</p>
      <div className="flex gap-x-4 justify-end">
        <div className="w-auto">
          <Popover
            content={content}
            trigger="click"
            open={open}
            placement="bottomLeft"
            onOpenChange={handleOpenChange}
          >
            <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px] bg-primary-300 w-max">
              <DVLAFilter className="overview-icon h-4 w-4" />
              <span>{reportName}</span>
              <DVLAChevronDown className="overview-icon" />
            </div>
          </Popover>
        </div>
        <div className="w-auto">
          <div
            onClick={handleOpenFilterChange}
            className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px] bg-primary-300"
          >
            <DVLACalendar className="h-4 w-4" />
            <span>{activeDateFilterName}</span>
            <DVLAChevronDown className="overview-icon" />
          </div>
          <Modal
            open={openFilter}
            closable={false}
            footer={null}
            onCancel={() => handleOpenFilterChange(false)}
            className={"filter-modal"}
          >
            {filterContent}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ReportsFilters;
