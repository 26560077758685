import { Types } from "../actions/reports.action";

const INITIAL_STATE = {
  loading: false,
  complianceReports: [],
  tripSummaryReports: [],
  highestTripDestinationsReportsReports: {},
  highestTripOriginsReportsReports: [],
};

export const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_COMPLIANCE_REPORTS_START:
    case Types.FETCH_TRIP_SUMMARY_REPORTS_START:
    case Types.FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_START:
    case Types.FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_START:
      return { ...state, loading: true };
    case Types.FETCH_COMPLIANCE_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        complianceReports: action.complianceReports,
      };
    case Types.FETCH_TRIP_SUMMARY_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        tripSummaryReports: action.tripSummaryReports,
      };
    case Types.FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        highestTripDestinationsReportsReports:
          action.highestTripDestinationsReportsReports,
      };
    case Types.FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        highestTripOriginsReportsReports:
          action.highestTripOriginsReportsReports,
      };
    case Types.FETCH_COMPLIANCE_REPORTS_END:
    case Types.FETCH_TRIP_SUMMARY_REPORTS_END:
    case Types.FETCH_HIGHEST_TRIP_DESTINATION_REPORTS_END:
    case Types.FETCH_HIGHEST_TRIP_ORIGIN_REPORTS_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
