import React from "react";
import "../styles.scss";
import { numberWithCommas } from "../../../utils/constants";

const SimpleChart = ({ label, value, formatType }) => {
  return (
    <div className="border border-link-active rounded-[8px] bg-main p-4 text-left">
      <p className="text-sm-regular font-medium">{label}</p>
      <p className="font-bold text-headline mt-2">{formatType === 'currency' ? '₦' + numberWithCommas(value) : value}</p>
    </div>
  );
};

export default SimpleChart;
