import React, { useEffect, useState } from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import Button from "../../../components/Buttons";
import { Field, Formik } from "formik";
import { DatePicker, Form, Input, Select, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { isValidPhoneNumber } from "react-phone-number-input";
import { localISOTime } from "../../../utils/constants";
import { UpdateDriverProfile } from "../../../redux/actions/drivers.action";
import dayjs from "dayjs";

const UpdateDriver = ({ handleClose, handleUpdate, driver, id }) => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.drivers.loading);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    licenseNumber: "",
    gender: "",
    residentialAddress: "",
    vehicleRegistrationNumber: "",
    verificationStatus: "",
    registrationDate: "",
    dateOfBirth: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (driver) {
      setValues({
        name: driver?.name ?? "",
        email: driver?.email ?? "",
        phoneNumber: driver?.phoneNumber ?? "",
        licenseNumber: driver?.licenseNumber ?? "",
        gender: driver?.gender ?? "",
        residentialAddress: driver?.residentialAddress ?? "",
        vehicleRegistrationNumber: driver?.vehicleRegistrationNumber ?? "",
        verificationStatus: driver?.verificationStatus ?? "",
        registrationDate: driver?.registrationDate ? dayjs(new Date(driver?.registrationDate)) : "",
        dateOfBirth: driver?.dateOfBirth ? dayjs(new Date(driver?.dateOfBirth)) : "",
      })
    }
  }, [driver])

  const updateDriver = (values, { resetForm }) => {
    const data = {
      ...values,
      registrationDate: localISOTime(new Date(values?.registrationDate)),
      dateOfBirth: localISOTime(new Date(values?.dateOfBirth)),
      id
    }
    dispatch(UpdateDriverProfile(data))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description: "Driver Profile Updated Successfully!",
        });
        resetForm();
        form.resetFields();
        handleUpdate();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  return (
    <div className="w-full">
      <div className="flex justify-end">
        <div
          className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>
      <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
        Update Driver Profile
      </div>
      <div className="text-labels mt-2">
        <Formik
          className="w-full"
          initialValues={values}
          onSubmit={updateDriver}
          enableReinitialize={true}
          validationSchema={validator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onFinish={handleSubmit} form={form}>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Driver's Name"
                    value={values?.name || ""}
                  >
                    <Form.Item
                      name={"name"}
                      help={
                        touched.name && errors.name
                          ? errors.name
                          : ""
                      }
                      validateStatus={
                        touched.name && errors.name
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"text"}
                            className={"form-field_input_2"}
                            value={values?.name || ""}
                            onChange={(e) => {
                              setFieldValue("name", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("name", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Email"
                    value={values?.email || ""}
                  >
                    <Form.Item
                      name={"email"}
                      help={
                        touched.email && errors.email
                          ? errors.email
                          : ""
                      }
                      validateStatus={
                        touched.email && errors.email
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"email"}
                            className={"form-field_input_2"}
                            value={values?.email || ""}
                            onChange={(e) => {
                              setFieldValue("email", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("email", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Phone Number (E.g +23412341234)"
                    value={values?.phoneNumber || ""}
                  >
                    <Form.Item
                      name={"phoneNumber"}
                      help={
                        touched.phoneNumber && errors.phoneNumber
                          ? errors.phoneNumber
                          : ""
                      }
                      validateStatus={
                        touched.phoneNumber && errors.phoneNumber
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"tel"}
                            className={"form-field_input_2"}
                            value={values?.phoneNumber || ""}
                            onChange={(e) => {
                              setFieldValue("phoneNumber", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("phoneNumber", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="License Number"
                    value={values?.licenseNumber || ""}
                  >
                    <Form.Item
                      name={"licenseNumber"}
                      help={
                        touched.licenseNumber && errors.licenseNumber
                          ? errors.licenseNumber
                          : ""
                      }
                      validateStatus={
                        touched.licenseNumber && errors.licenseNumber
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"text"}
                            className={"form-field_input_2"}
                            value={values?.licenseNumber || ""}
                            onChange={(e) => {
                              setFieldValue("licenseNumber", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("licenseNumber", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Gender"
                    value={values?.gender || ""}
                  >
                    <Form.Item
                      name={"gender"}
                      help={
                        touched.gender && errors.gender
                          ? errors.gender
                          : ""
                      }
                      validateStatus={
                        touched.gender && errors.gender
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.gender || ""}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("gender", e);
                            }}
                            options={[
                              { value: "MALE", label: "Male" },
                              { value: "FEMALE", label: "Female" },
                            ]}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Residential Address"
                    value={values?.residentialAddress || ""}
                  >
                    <Form.Item
                      name={"residentialAddress"}
                      help={
                        touched.residentialAddress && errors.residentialAddress
                          ? errors.residentialAddress
                          : ""
                      }
                      validateStatus={
                        touched.residentialAddress && errors.residentialAddress
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"text"}
                            className={"form-field_input_2"}
                            value={values?.residentialAddress || ""}
                            onChange={(e) => {
                              setFieldValue("residentialAddress", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("residentialAddress", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Vehicle Registration Number"
                    value={values?.vehicleRegistrationNumber || ""}
                  >
                    <Form.Item
                      name={"vehicleRegistrationNumber"}
                      help={
                        touched.vehicleRegistrationNumber && errors.vehicleRegistrationNumber
                          ? errors.vehicleRegistrationNumber
                          : ""
                      }
                      validateStatus={
                        touched.vehicleRegistrationNumber && errors.vehicleRegistrationNumber
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Input
                            type={"text"}
                            className={"form-field_input_2"}
                            value={values?.vehicleRegistrationNumber || ""}
                            onChange={(e) => {
                              setFieldValue("vehicleRegistrationNumber", e.target.value);
                            }}
                            onBlur={(e) => {
                              setFieldValue("vehicleRegistrationNumber", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Verification Status"
                    value={values?.verificationStatus || ""}
                  >
                    <Form.Item
                      name={"verificationStatus"}
                      help={
                        touched.verificationStatus && errors.verificationStatus
                          ? errors.verificationStatus
                          : ""
                      }
                      validateStatus={
                        touched.verificationStatus && errors.verificationStatus
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <Select
                            value={values?.verificationStatus || ""}
                            onBlur={handleBlur}
                            className={"form-field_input_2"}
                            onChange={(e) => {
                              setFieldValue("verificationStatus", e);
                            }}
                            options={[
                              { value: "PENDING", label: "Pending" },
                              { value: "VERIFIED", label: "Verified" },
                              { value: "FAILED", label: "Failed" },
                            ]}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Registration Date"
                    value={values?.registrationDate || ""}
                  >
                    <Form.Item
                      name={"registrationDate"}
                      help={
                        touched.registrationDate && errors.registrationDate
                          ? errors.registrationDate
                          : ""
                      }
                      validateStatus={
                        touched.registrationDate && errors.registrationDate
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <DatePicker
                            showTime
                            className={"form-field_input_2"}
                            placeholder={""}
                            value={values?.registrationDate || ""}
                            onChange={(e) => {
                              setFieldValue("registrationDate", e);
                            }}
                            onOk={(e) => {
                              setFieldValue("registrationDate", e)
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"mb-4"}>
                <FieldContainer iconPlacementCss={"iconCss"}>
                  <FloatLabel
                    label="Date of Birth"
                    value={values?.dateOfBirth || ""}
                  >
                    <Form.Item
                      name={"dateOfBirth"}
                      help={
                        touched.dateOfBirth && errors.dateOfBirth
                          ? errors.dateOfBirth
                          : ""
                      }
                      validateStatus={
                        touched.dateOfBirth && errors.dateOfBirth
                          ? "error"
                          : "success"
                      }
                    >
                      <Field>
                        {({ form: { setFieldValue } }) => (
                          <DatePicker
                            showTime
                            className={"form-field_input_2"}
                            placeholder={""}
                            value={values?.dateOfBirth || ""}
                            onChange={(e) => {
                              setFieldValue("dateOfBirth", e);
                            }}
                            onOk={(e) => {
                              setFieldValue("dateOfBirth", e)
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </FloatLabel>
                </FieldContainer>
              </div>
              <div className={"flex justify-center"}>
                <Button
                  css={"w-full"}
                  state={"PRIMARY"}
                  text={"Update Driver"}
                  type={"submit"}
                  loading={loader}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  name: yup
    .string()
    .min(3, "Driver's name should be minimum of 3 characters")
    .required("Driver's name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .test("is-valid-phone", "Phone number is invalid", (value) => {
      return isValidPhoneNumber(value || "");
    }),
  licenseNumber: yup
    .string()
    .min(3, "License number should be minimum of 3 characters")
    .required("License number is required"),
  gender: yup
    .string()
    .required("Gender is required"),
  residentialAddress: yup
    .string()
    .min(10, "Residential address should be minimum of 10 characters")
    .required("Residential address is required"),
  vehicleRegistrationNumber: yup
    .string()
    .min(3, "Vehicle registration number should be minimum of 10 characters")
    .required("Vehicle registration number is required"),
  verificationStatus: yup
    .string()
    .required("Verification status is required"),
  registrationDate: yup
    .string()
    .required("Registration date is required"),
  dateOfBirth: yup
    .string()
    .required("Date of birth is required"),
});

export default UpdateDriver;
