import moment from "moment";
import DVLALogoText from "../assets/imgs/dvla-logo-text.png";
import DVLAAbujaLogoText from "../assets/imgs/dvla-abuja-logo-text.png";
import DVLARiversLogoText from "../assets/imgs/dvla-rivers-logo-text.png";

const TENANT = process.env.REACT_APP_TENANT;

const MONTHS = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const flCapital = (s) =>
  s !== null ? s.charAt(0).toUpperCase() + s.slice(1) : "";

export const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    let _month = (date.getMonth() + 1).toString();
    let month = _month.length === 1 ? "0" + _month : _month;
    let _day = date.getDate().toString();
    let day = _day.length === 1 ? "0" + _day : _day;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return day + "/" + month + "/" + date.getFullYear() + "  " + strTime;
  }
  return "";
};

export const dateFormat = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    let month = date.getMonth() + 1;
    let _day = date.getDate().toString();
    let day = _day.length === 1 ? "0" + _day : _day;
    return day + " " + MONTHS[month] + ", " + date.getFullYear();
  }
  return "";
};

export const localISOTime = (date) => {
  let x = new Date().getTimezoneOffset() * 60000;
  let isoTime = new Date(date - x).toISOString().slice(0, -1);
  return isoTime.split("T").join(" ");
};

export const today = () => {
  let now = new Date(Date.now());
  let lastMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  let startDate = localISOTime(lastMidnight);
  let endDate = localISOTime(now);
  return { startDate, endDate };
};

export const yesterday = () => {
  let now = new Date(Date.now());
  let lastTwoMidnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1
  );
  let startDate = localISOTime(lastTwoMidnight);
  let endDate = localISOTime(lastTwoMidnight);
  return { startDate, endDate };
};

export const thisWeek = () => {
  let startDate = localISOTime(moment().startOf("week").toDate());
  let endDate = localISOTime(moment().endOf("week").toDate());
  return { startDate, endDate };
};

export const lastWeek = () => {
  let startDate = localISOTime(
    moment().subtract(1, "weeks").startOf("week").toDate()
  );
  let endDate = localISOTime(
    moment().subtract(1, "weeks").endOf("week").toDate()
  );
  return { startDate, endDate };
};

export const thisMonth = () => {
  const startDate = localISOTime(moment().startOf("month").toDate());
  let endDate = localISOTime(new Date(Date.now()));
  return { startDate, endDate };
};

export const lastMonth = () => {
  const startDate = localISOTime(
    moment().subtract(1, "months").startOf("month").toDate()
  );
  const endDate = localISOTime(
    moment().subtract(1, "months").endOf("month").toDate()
  );
  return { startDate, endDate };
};

export const thisYear = () => {
  const startDate = localISOTime(moment().startOf("year"));
  let endDate = localISOTime(new Date(Date.now()));
  return { startDate, endDate };
};

export const lastYear = () => {
  const startDate = localISOTime(moment().subtract(1, "years").startOf("year"));
  let endDate = localISOTime(moment().subtract(1, "years").endOf("year"));
  return { startDate, endDate };
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const download = (dataurl, fileName) => {
  const link = document.createElement("a");
  link.href = dataurl;
  link.target = "_blank";
  if (fileName) {
    link.download = fileName;
  }
  link.click();
};

export const goBack = () => {
  window.history.back();
};

export const getTenantLayoutClass = () => {
  switch (TENANT) {
    case "LAGOS":
      return "auth-left-pain";
    case "ABUJA":
      return "auth-abuja-left-pain";
    case "RIVERS":
      return "auth-rivers-left-pain";
    default:
      return "auth-left-pain";
  }
};

export const getTenantLogoText = () => {
  switch (TENANT) {
    case "LAGOS":
      return DVLALogoText;
    case "ABUJA":
      return DVLAAbujaLogoText;
    case "RIVERS":
      return DVLARiversLogoText;
    default:
      return DVLALogoText;
  }
};

export const getTenantFavicon = () => {
  switch (TENANT) {
    case "LAGOS":
      return "lagos-favicon.ico";
    case "ABUJA":
      return "abuja-favicon.ico";
    case "RIVERS":
      return "rivers-favicon.ico";
    default:
      return DVLALogoText;
  }
};

export const GOOGLE_MAP_API_KEY = "AIzaSyBAO53zufCm86oZc_19iJqGwC9sBjnApvE";

export const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};
