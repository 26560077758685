/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { numberWithCommas } from "../../../utils/constants";
import ReactECharts from "echarts-for-react";

const initialOptions = {
  grid: { top: 8, right: 8, bottom: 50, left: 70 },
  tooltip: {
    trigger: "axis",
    formatter: (params) =>
      params
        .map((item) => `${item.name}: ${numberWithCommas(item.value)}`)
        .join("<br/>"),
  },
  xAxis: {
    type: "category",
    data: [],
    axisLabel: {
      fontSize: 10,
    },
  },
  yAxis: {
    type: "value",
    name: "",
    nameLocation: "middle",
    nameGap: 50,
    nameTextStyle: {
      fontWeight: "bold",
      fontSize: 14,
    },
    axisLabel: {
      formatter: (value) => numberWithCommas(value),
    },
    min: 0,
  },
  series: [
    {
      name: "Total trips",
      data: [],
      type: "bar",
      smooth: true,
      barWidth: "10%",
      itemStyle: {
        color: "#D7A81E",
        borderRadius: [50, 50, 0, 0],
      },
      label: {
        show: true,
        position: "top",
        fontSize: 10,
        fontWeight: "bold",
        color: "#000",
        formatter: (params) => `${numberWithCommas(params.value)}`,
      },
    },
  ],
};

const BarChart = ({ report = {} }) => {
  const [chartOptions, setChartOptions] = useState(initialOptions);

  useEffect(() => {
    const isNumberFormat = report.formatType === "number";
    const currencySymbol = isNumberFormat ? "" : "₦";

    const data = report?.value?.map((r) => ({
      value: r.value,
      name: r.key,
    }));

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      tooltip: {
        trigger: "axis",
        formatter: (params) =>
          params
            .map((item) => `${item.name}: ${currencySymbol}${numberWithCommas(item.value)}`)
            .join("<br/>"),
      },
      xAxis: { ...prevOptions?.xAxis, data: data.map((item) => item.name) },
      series: prevOptions.series?.map((s) => ({
        ...s,
        data,
        label: {
          ...s.label,
          formatter: (params) => `${currencySymbol}${numberWithCommas(params.value)}`,
        },
      })),
    }));
  }, [report]);

  return (
    <div className="w-full monitor-card bg-white p-5 flex flex-col gap-y-2">
      <p className="text-sm-headline font-bold mb-2 text-left">{report?.title}</p>
      <ReactECharts option={chartOptions} style={{ height: 300 }} />
      <div className="flex items-center justify-center gap-x-2">
        <div className="h-2 w-2 rounded-full bg-link-active"></div>
        <p className="text-labels font-semibold">{report?.title}</p>
      </div>
    </div>
  );
};

export default BarChart;
